.establishment-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #ffffff;
  color: var(--color);
  overflow: hidden;
  z-index: 5;
}

.list-establishments-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
  overflow-y: auto;
  padding: 1em;
}

.small-list-establishments-container {
  grid-template-columns: repeat(2, 1fr);
}

.establishment-container {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 0 1em;
  font-size: 14px;
  cursor: pointer;
}

.establishment-icon {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.establishment-name {
  font-weight: 600;
}

.establishment-icon img,
.establishment-icon svg {
  width: 35px;
  height: 35px;
  object-fit: contain;
}

.establishment-icon svg path {
  fill: #fff;
}

.establishment-distance {
  display: flex;
  align-items: center;
  gap: 0 0.5em;
}

.establishment-distance svg {
  width: 12px;
  height: 12px;
}

.establishment-distance svg path {
  fill: var(--color);
}
.establishment-details {
  display: flex;
  flex-direction: column;
  gap: 0.25em 0;
  padding: 1em 1em 1em 0;
  font-size: 12px;
}

.establishment-container .other-details {
  display: flex;
  justify-content: space-between;
}

.establishment-container .establishment-category {
  background: #000;
  color: #fff;
  border-radius: 2px;
  padding: 1pt 5pt;
}

.establishment-wrapper .establishment-header {
  display: flex;
  justify-content: space-between;
  padding: 10pt;
}

.establishment-wrapper .establishment-header svg {
  height: 20px;
  width: auto;
}

@media screen and (max-width: 768px) {
  .establishment-wrapper {
    color: #ffffff;
    background: #000000;
  }

  .list-establishments-container {
    grid-template-columns: 2fr;
    padding: 0 0 1em;
  }
}
