.feedback-btn img {
  width: 25px;
}

.feedback-form-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.feedback-form {
  color: #fff;
  position: absolute;
  top: 35%;
  left: 33%;
  width: 35%;
  background: var(--color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em 0;
  padding: 1em;
  font-size: 14px;
  z-index: 9999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.small-form {
  top: 20%;
  left: 25%;
  width: 50%;
  font-size: 12px;
}

.feedback-body {
  padding: 0.75rem 0;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.required-field,
.field-error {
  color: #e94032;
}

.field-error {
  margin: 0.2rem 0;
}

.submit-wrapper {
  top: 30%;
}

.feedback-form > form > textarea {
  margin: 1em 0;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
}

form {
  width: 100%;
}

.feedback-header {
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 2px;
}

.feedback-bottom {
  display: flex;
  align-items: center;
}

.feedback-button {
  background: #fff;
  color: var(--color);
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  height: fit-content;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.feedback-btn-small {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1em;
  background: #0f5671;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-size: 10px;
  color: #fff;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  border-radius: 1em;
}

.feedback-btn:hover {
  border: 2px solid #fff;
}

.feedback-btn-small img {
  width: 12.5px;
}

.feedback-btn {
  width: 40px;
  height: 40px;
}

.feedback-btn span {
  display: none;
}

.feedback-btn img {
  width: 20px;
}

.feedback-form-close {
  width: 17.5px;
  position: absolute;
  top: 3px;
  right: 3px;
  margin-top: 0.5em;
  margin-right: 0.5em;
  cursor: pointer;
}

.submission-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
}

.submission-container > div {
  margin: 0.5rem auto;
  text-align: center;
}

.submission-container > div > p {
  margin: 0;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .feedback-form {
    top: 34%;
    left: 12%;
    width: 70%;
  }
}