.loader-container {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem 0;
  color: #fff;
  font-size: 14px;
  backdrop-filter: blur(var(--blur-value));
  background: rgba(0, 0, 0, 0.25);
  z-index: 3;
}

.loader-container img {
  height: 35px;
  width: auto;
}

.compare-loader-container img {
  height: 20px;
}


.circular-loader:before {
  content: '';
  display: block;
  height: var(--loader-size);
  width: var(--loader-size);
  -webkit-animation: spin .5s infinite linear;
          animation: spin .5s infinite linear;
  border: 3px #bebebe solid;
  border-left-color: #fff;
  border-radius: 100%; }

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }