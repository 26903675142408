.reached-limit-container {
  top: -1px;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background: #000;
  z-index: 3;
  overflow-y: auto;
}

.reached-limit-card,
.reached-limit-survey-msg,
.survey-header,
.limit-survey-questions {
  width: 100%;
  display: flex;
  background: #fff;
  padding: 2rem;
  z-index: 1;
}

.reached-limit-card {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem 0;
  text-align: center;
  margin-top: auto;
}

.reached-limit-header {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.reached-limit-header {
  line-height: 42px;
  font-size: 22px;
}

.reached-limit-header .title {
  font-size: 30px;
  font-weight: 400;
}

.reached-limit-container form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  background-color: #1a1a1a;
  align-items: center;
  box-sizing: border-box;
  padding: 2.5rem;
}

.reached-limit-container form input {
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 10px;
}

.reached-limit-card .form-error-message {
  width: fit-content;
  grid-column: 1;
  grid-row: 2;
}

.reached-limit-container form button {
  padding: 10px 20px;
  color: #fff;
  background: none;
  font-size: 16px;
  font-family: var(--font-family);
  cursor: pointer;
  border: 1px solid #fff
}


.reached-limit-survey-msg {
  align-items: end;
  justify-content: space-between;
  font-size: 20px;
  margin-bottom: auto;
}

.reached-limit-survey-msg .message,
.survey-header .message {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 70%;
}

.reached-limit-survey-msg .message .title ,
.survey-header h1{
  font-size: 26px;
  font-weight: 400;
}

.reached-limit-survey-msg .privacy-policy-btn {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
  font-size: 16px;
  color: #fe572a;
  cursor: pointer;
}

.reached-limit-survey-msg .privacy-policy-btn svg {
  height: 18px;
  width: auto;
}

.reached-limit-survey-msg
.privacy-policy-btn
svg
path {
  stroke: #fe572a;
}

.reached-limit-survey-msg
.privacy-policy-btn
svg
path:first-child {
  fill: #fe572a;
  stroke: unset;
}

.reached-limit-survey-msg button {
  color: #fff;
  background: #fe572a;
  border: none;
  padding: 0.7rem 3rem;
  font-size: 18px;
  cursor: pointer;
  font-family: var(--font-family);
}

.reached-limit-survey-msg .form-error-message {
  margin-bottom: 0.5rem;
}

.survey-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 4rem 0;
}

.survey-header {
  justify-content: space-between;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
}

.survey-header svg {
  height: 60px;
  width: auto;
}

.survey-container .question-container {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: 18px;
  padding-top: 1.5rem;
}


.survey-container .limit-survey-questions {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  flex-direction: column;
  gap: 1.5rem 0;
  padding: 3rem;
}

.survey-container .question-container .question {
  font-weight: 500;
}

.survey-container .question-container .options-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.survey-container .question-container .option {
  display: flex;
  align-items: flex-start;
}

.survey-container .question-container label,
.survey-container .question-container textarea {
  width: 100%;
  margin-left: 0.5rem;
}

.survey-container .question-container textarea {
  padding: 0.5rem 1rem;
  font-family: var(--font-family);
}

.survey-container .question-container .sub-question,
.survey-container .question-container .sub-options .rating {
  font-size: 16px;
}

.survey-container .question-container .sub-options {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
}

.survey-container .question-container .sub-options .rating {
  padding: 0.1rem 0.6rem;
  border: 1px solid #000;
  border-radius: 5px;
  line-height: normal;
  transition: all 0.3s ease;
  cursor: pointer;
}

.survey-container .question-container .sub-options .rating.selected {
  background: #fe572a;
  color: #fff;
  border: 1px solid #fe572a;
}

.survey-container .additional-comments textarea,
.survey-container .recommend-to textarea {
  margin-left: 1.813rem;
}

.survey-container .question-container .form-error-message {
  width: fit-content;
}

.limit-survey-questions .complete-survey-btn,
.survey-ty-container .buttons > * {
  max-width: 280px;
  padding: 0.6rem 3.5rem;
  margin: 0 auto;
  color: #fe572a;
  background: none;
  border: 1px solid #fe572a;
  font-size: 16px;
  font-family: var(--font-family);
  cursor: pointer;
}

.limit-survey-questions .complete-survey-btn {
  margin-top: 1.5rem;
}

.survey-ty-container {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem 0;
  background: #fff;
  font-size: 18px;
  padding: 5rem 0;
}

.survey-ty-container h1 {
  font-size: 28px;
  font-weight: 400;
}

.survey-ty-container svg:not(.logo) {
  height: 50px;
  width: auto;
}

.survey-ty-container .message {
  max-width: 70%;
  text-align: center;
}

.survey-ty-container .buttons {
  margin-top: 2rem;
}

.survey-ty-container .buttons > * {
  margin: 0 0.5rem;
}

@media screen and (max-width: 768px) {
  .reached-limit-container.vertically-centered {
    justify-content: center;
  }

  .reached-limit-survey-msg .message,
  .survey-header .message {
    max-width: 100%;
  }

  .reached-limit-header,
  .reached-limit-survey-msg .message {
    font-size: 18px;
  }

  .reached-limit-header .title,
  .reached-limit-survey-msg .message .title,
  .survey-header h1 {
    font-size: 20px;
    font-weight: 500;
  }

  .reached-limit-container form {
    grid-template-columns: 1fr;
  }

  .reached-limit-card {
    margin: 0;
  }

  .reached-limit-card,
  .reached-limit-survey-msg {
    padding: 2rem 0;
  }

  .reached-limit-card form .message-field {
    grid-column: unset;
    grid-row: 2;
  }

  .reached-limit-survey-msg {
    flex-direction: column;
    gap: 1rem 0;
    margin: 0;
  }

  .reached-limit-survey-msg .message {
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .reached-limit-survey-msg .message span {
    padding: 0 1rem;
  }

  .reached-limit-survey-msg button {
    margin: 0 auto;
  }

  .reached-limit-card .form-error-message {
    width: unset;
  }

  .reached-limit-survey-msg div:nth-child(2) {
    margin: 0 auto;
  }

  .survey-container {
    padding: 0;
    height: 100%;
  }

  .survey-container .question-container .sub-options {
    justify-content: center;
  }

  .survey-container .additional-comments textarea,
  .survey-container .recommend-to textarea {
    margin-left: unset;
  }

  .survey-ty-container h1 {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

  .survey-header {
    flex-direction: column-reverse;
    gap: 1rem 0;
    text-align: center;
  }

  .survey-header svg {
    height: 80px;
  }

  .survey-ty-container {
    height: 100%;
    padding: 2rem;
  }

  .survey-ty-container .message {
    max-width: 100%;
  }

  .survey-ty-container .buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem 0;
  }

  .survey-ty-container .buttons > * {
    font-size: 16px;
  }
}