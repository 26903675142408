.Footer {
  background-color: #211f1f;
  color: white;
  text-align: left;
  padding: 0 0 2em;
}

.Footer a {
  text-decoration: none;
}

.footer-top {
  position: relative;
  background-color: #282c34;
  background: url('../../assets/landing-page/footer-bg.png') no-repeat top
    center;
  background-size: cover;
  width: 100%;
  height: 275px;
  padding: 30px 20px;
  text-align: center;
  z-index: 0;
  /* filter: grayscale(100%); */
}

/* .footer-top::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  );
  z-index: -1;
} */

.footer-top-content {
  max-width: 800px;
  margin: 0 auto;
}

.footer-top h1 {
  font-size: 36px;
  font-weight: 500;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.footer-left {
  flex: 1;
}

.footer-left h2 {
  font-size: 16px;
  font-weight: 500;
}

.footer-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
}

.footer-logo {
  width: 100px;
  margin-bottom: 20px;
}

.footer-right .footer-top-link {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0 1rem;
  margin-top: 20px;
  color: white;
}

.footer-right .footer-top-link > * {
  cursor: pointer;
}

.footer-right .footer-top-link svg {
  height: 20px;
  width: auto;
}

.footer-social-media {
  display: flex;
  justify-content: center;
  margin-left: auto;
  width: fit-content;
}

.footer-social-media a:not(:last-child) {
  margin-right: 25px;
}

.footer-social-media svg {
  height: 20px;
  width: auto;
}

.footer-bottom p {
  margin-top: 20px;
  font-size: 12px;
}

.footer-company-info-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 350px;
}

.footer-company-info-wrapper p {
  line-height: 16px;
}

@media (max-width: 768px) {
  .footer-top h1 {
    font-size: 28px;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-company-info p,
  .footer-contact-info p {
    margin-top: 10px;
  }

  .footer-left, .footer-right {
      flex: 1 1 100%;
  }

  .footer-right {
      text-align: center;
  }

  .footer-right .footer-top-link {
    justify-content: center;
    margin: 20px 0;
  }

  .footer-social-media {
    margin: 0 auto;
  }

  .footer-social-media a {
    margin: 0 10px;
  }

  .footer-company-info-wrapper {
    flex-direction: column;
  }

  .footer-company-info,
  .footer-contact-info {
    margin-bottom: 20px;
  }
}
