.walking-distance-container {
  margin: 1.5em 0;
}

.modern-walking-distance-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 1rem 0;
  gap: 0 1rem;
  font-size: 14px;
}

.walking-distance-container-plugin {
  font-size: 14px;
}

.mobile-dashboard-content .modern-walking-distance-container {
  margin: 0;
  padding: 0;
}

.buttons-container, .dashboard-wd-buttons-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 0.5em;
  justify-content: space-between;
}

.buttons-container > * {
  margin: 0 auto;
}

.walking-distance-button {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #4f4f4f;
  padding: 0.5em 1em;
  color: #4f4f4f;
  font-family: inherit;
  font-size: 11px;
}

.walking-distance-button::before {
  content: '';
  position: absolute;
  right: 0;
  width: 50%;
  height: 1px; /* Adjust the height of the line */
  background: #4f4f4f; /* Line color */
  transform: translateY(-50%); /* Center the line vertically */
}

.walking-distance-button::after {
  content: '';
  position: absolute;
  top: 49%;
  left: 50%;
  width: 8px;
  height: 8px;
  background: #4f4f4f;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.modern-walking-distance-button {
  width: 60px;
  height: 60px;
  background: #404040;
  color: #fff;
  transform: scale(0.9);
}

.modern-walking-distance-button > .walking-distance,
.modern-walking-distance-button > .walking-time {
  font-weight: 500;
  font-size: 10px;
}

.modern-walking-distance-button::before {
  content: '';
  position: absolute;
  right: 0;
  width: 50%;
  height: 1px;
  background: #fff;
  transform: translateY(-50%);
}

.modern-walking-distance-button::after {
  content: '';
  position: absolute;
  top: 49%;
  left: 50%;
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.walking-distance {
  font-weight: 500;
  line-height: 15.6px;
  /* margin-bottom: 10px; */
}

.walking-time {
  font-weight: 300;
  font-size: 12px;
  line-height: 15.6px;
  margin-top: 0;
}

.selected-button {
  color: #fff;
  background: var(--color);
  border: 1px solid var(--color);
}

.selected-button::before {
  background: #fff;
}

.selected-button::after {
  background: #fff;
}

.modern-selected-button {
  background: #D9D9D9;
  color: #404040;
  border: 1px solid #fff;
}
.modern-selected-button::before {
  background: #404040;
}

.modern-selected-button::after {
  background: #404040;
}

.dark-walking-distance-button {
  color: #fff;
  background: var(--color);
  border: 1px solid #fff;
}

.dark-walking-distance-button::before {
  background: #fff;
}

.dark-walking-distance-button::after {
  background: #fff;
}

.dark-selected-button {
  color: var(--color);
  background: #fff;
  border: 1px solid #fff;
}

.dark-selected-button::before {
  background: var(--color);
}

.dark-selected-button::after {
  background: var(--color);
}

.dasboard-buttons-container {
  display: flex;
  gap: 0 0.9em;
}

.dashboard-walking-distance-button {
  background: #fff;
  position: relative;
  width: 75px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #4f4f4f;
  padding: 0.5em 1em;
  color: #4f4f4f;
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
}

.selected-dashboard-walking-distance-button {
  color: #0f5671;
  border: 1px solid #0f5671;
}

.dashboard-walking-distance-text {
  font-weight: 800;
  font-size: 13px;
  line-height: 15.6px;
}

.dashboard-wd-buttons-wrapper .dashboard-wd-button {
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  border-radius: 50%;
}

.dashboard-wd-buttons-wrapper > * {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.dashboard-wd-buttons-wrapper .dashboard-wd-button.selected {
  color: #fff;
}

.dashboard-wd-button .icon-wrapper .line {
  position: absolute;
  bottom: 50%;
  left: 25%;
  background-color: white;
  z-index: 1;
}

.dashboard-wd-buttons-wrapper .dashboard-wd-button .icon-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.dashboard-wd-button .icon-wrapper svg path {
  fill: #e0dede;
}

.dashboard-wd-button.selected .icon-wrapper svg path {
  fill: #ffffff;
}

.dashboard-wd-button .icon-wrapper .line {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #e0dede;
  z-index: 1;
}

.dashboard-wd-buttons-wrapper .dashboard-wd-button .icon-wrapper.small {
  width: 25px;
}

.dashboard-wd-button .icon-wrapper.small .line {
  width: 12px; /* Short line */
  height: 1px;
  left: -2px;
}

.dashboard-wd-button .icon-wrapper > svg {
  z-index: 1;
}

.dashboard-wd-button.selected .icon-wrapper > svg  path {
  fill: #000000;
  stroke: #000000;
}

.dashboard-wd-button .icon-wrapper.small .outer-circle {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 1px solid white;
  border-radius: 50%;
  cursor: pointer;
}

.dashboard-wd-button .icon-wrapper .outer-circle {
  background: none;
}

.dashboard-wd-button.selected .icon-wrapper .outer-circle {
  background: #ffffff;
}

.dashboard-wd-buttons-wrapper .dashboard-wd-button .icon-wrapper.medium {
  width: 30px;
}

.dashboard-wd-button .icon-wrapper.medium .line {
  left: -12%;
  width: 15px; /* Short line */
  height: 1px;
  color: #ffffff;
}

.dashboard-wd-button.selected .icon-wrapper .line {
  background-color: #000000;
}

.dashboard-wd-button .icon-wrapper.medium .outer-circle {
  position: absolute;
  width: 40px;
  height: 40px;
  border: 1px solid white;
  border-radius: 50%;
}


.dashboard-wd-buttons-wrapper .dashboard-wd-button .icon-wrapper.large {
  width: 50px;
}

.dashboard-wd-button .icon-wrapper.large .line {
  left: -2px;
  width: 24px;
  height: 1px;
}

.dashboard-wd-button .icon-wrapper.large .outer-circle {
  position: absolute;
  width: 55px;
  height: 55px;
  border: 1px solid white;
  border-radius: 50%;
}

.dashboard-wd-container > .walking-distance {
  color: #a9a9a9;
}

.dashboard-wd-container > .walking-distance.selected {
  color: #ffffff;
}