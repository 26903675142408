.dashboard-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /* z-index: 100; */
  background-color: #ffffff;
  overflow: hidden;
  box-sizing: border-box;
  overflow-y: auto;
  transition: all 0.5s ease;
}

.modern-blurred-bg::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(1px);
}

.modern-blurred-bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background-image: url('../../assets/map-bg.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: all 0.5s ease;
}

.divider-dashboard {
  width: 100%;
  height: 1px;
  background: #d9d9d9;
}

.dashboard-summary-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1em 0;
  width: 100%;
  max-width: 950px;
  font-family: var(--font-family);
  backdrop-filter: blur(1px);
  border: 1px solid #d7d7d7;
}

.dashboard-summary-container-plugin {
  padding: 0;
}

.dashboard-summary-container-mobile {
  height: 100%;
  justify-content: normal !important;
}

.dashboard-summary-container-plugin {
  transform: scale(0.9);
}

.dashboard-summary-smaller {
  width: -webkit-fill-available;
  width: -moz-available;
}

.dashboard-header {
  width: 100%;
  display: flex;
  flex-direction: row-reverse !important;
  justify-content: space-between;
  align-items: flex-end;
}

.dashboard-header img {
  width: 30px;
  height: auto;
}

.dashboard-header svg {
  width: 30px;
  height: auto;
}

.dashboard-header svg path {
  fill: var(--color);
}

.modern-dashboard-header {
  display: none;
}

.modern-dashboard-header h2 {
  font-weight: 500;
}

.radar-header-mobile {
  margin: 0 auto;
  flex-direction: column !important;
  align-items: center;
}

.radar-header-mobile h2 {
  font-size: 20px;
}

.tutorial-container,
.change-address-container,
.filter-search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.8em;
}

.tutorial-container p,
.change-address-container p,
.filter-search-container p {
  margin: 0;
}

.tutorial-container svg,
.change-address-container svg,
.filter-search-container svg {
  height: 20px;
  width: auto;
}

.header-buttons-skeleton {
  display: flex;
  gap: 1rem;
}

.dasboard-action-buttons {
  display: flex;
  gap: 0 0.9em;
}

.modern-dasboard-action-buttons > .tutorial-container,
.modern-dasboard-action-buttons > .change-address-container,
.modern-dasboard-action-buttons > .filter-search-container {
  gap: 0.5rem;
  width: max-content;
}

.toggle-switch-mobile {
  scale: 1.234;
}

.city-bg {
  opacity: 0.4;
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.dashboard-container {
  display: grid;
  grid-template-columns: 350px 1fr;
  justify-content: center;
  gap: 0.75em;
  width: 100%;
}

.dashboard-container-mobile {
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  padding-bottom: var(--pb);
}

.modern-dashboard-container {
  position: relative;
}

.dashboard-container-plugin {
  grid-template-columns: 300px 1fr;
}

.dashboard-container-plugin .modern-dashboard-type-btn {
  min-width: unset !important;
}

.dashboard-score-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5em 1.5em 0;
  border-radius: 24.116px;
  background: #fff;
  box-shadow: 0px 0px 16.44289px 0px rgba(0, 0, 0, 0.25);
  gap: 0.5em 0;
}

.modern-dashboard-score-container {
  position: relative;
  background: var(--color);
  color: #fff;
  border-radius: 0;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
}

.modern-dashboard-score-container .modern-back-btn {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.dashboard-summary-details {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.dashboard-summary-details-header {
  width: 100%;
}

.modern-dashboard-summary-details-header {
  background: var(--color);
  padding-bottom: 0.5rem;
}

.modern-dashboard-summary-details-header .modern-summary-header-buttons {
  display: flex;
  justify-content: space-between;
  gap: 0 14pt;
  color: #fff;
  padding: 0.5rem 1rem 0;
}

.modern-dashboard-summary-details-header
  .modern-summary-header-buttons
  svg
  path {
  fill: #fff;
}

.modern-address {
  font-size: 14px;
  font-weight: 500;
}

.dashboard-score {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em 2em;
}

.modern-custom-button-icon .score-diamond {
  top: 2.5px;
}

.modern-small-score-icon h1 {
  font-size: 14px;
  font-weight: 500;
  margin: 0 !important;
}

.walkable-text {
  color: #fff;
  padding: 0.25em;
  background: #0f5671;
}

.dashboard-walking-distance {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em 0;
  margin-top: 0.6em;
}

.modern-dashboard-walking-distance {
  flex-direction: row;
  margin: 0;
}

.modern-dashboard-walking-distance > span {
  font-size: 15px;
  text-transform: uppercase;
}
/* DASHBOARD SUMMARY DETAILS */
.property-summary-details {
  display: flex;
  gap: 0 1.5em;
  width: 100%;
}

.property-summary-details > div > * {
  font-size: 20px;
}

.property-summary-details img {
  width: 300px;
  max-height: 190px;
  border-radius: 1em;
  object-fit: cover;
}

.property-summary-details-plugin > div > * {
  font-size: 16px;
}

.property-summary-details-plugin img {
  width: auto;
  max-height: 130px;
}

.dashboard-button-container {
  display: flex;
  gap: 0 1em;
  width: 100%;
  margin-top: auto;
}

.dashboard-type-description {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  color: #0f5671;
}

.category-button-list {
  display: flex;
  gap: 0.5em;
}

.dashboard-button-container button {
  width: 100%;
  background: var(--color);
  font-size: 14px;
  font-weight: bold;
  font-family: inherit;
  color: #114558;
  border: none;
  cursor: pointer;
  padding: 0.65em 1em;
  text-transform: uppercase;
  margin: 0.5em 0;
  width: 100%;
  border-radius: 1em;
  color: #fff;
}

.modern-dashboard-button-container button {
  margin: 0;
  font-weight: 500;
  background: var(--color);
  color: #fff;
  border: 1px solid var(--color);
  border-radius: 0;
  padding: 0.8rem 1rem;
}

.feature-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-title-container svg {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.feature-title-container svg path {
  fill: var(--color);
}

.info-popup {
  z-index: 10000;
  background-color: var(--color) !important;
  width: max-content !important;
  color: #fff !important;
  opacity: 1 !important;
  font-size: 12px !important;
}

.hide-dashboard {
  display: none !important;
}

.default-cursor {
  cursor: unset;
}

.dashboard-search-address-input {
  width: calc(100% - (1rem + 4px)) !important;
  border: 2px solid var(--color) !important;
  background: #fff;
}

.dashboard-search-address-btn {
  display: flex;
  width: 200px;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #fff;
  padding: 0.2rem 0.5rem;
  font-size: 14px;
  background: var(--color) !important;
  cursor: pointer;
}

.dashboard-search-address-btn svg path {
  fill: #fff !important;
}

.dashboard-search-address-input > input {
  background: none;
  font-size: 14px !important;
  font-family: var(--font-family);
}

.dashboard-search-address-input > input,
.dashboard-search-address-input > input:disabled {
  color: #000;
}

.blur-bg {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.slider-toggle-container {
  position: fixed;
  top: 445px;
  left: -0.5px;
  height: -webkit-fill-available;
  height: -moz-available;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  border-radius: 10px 10px 0 0;
  background: #000;
  padding: 0 1rem 4rem;
  transition: top 0.3s ease;
}

.iphone-slider-toggle-container .button-container {
  margin-bottom: 10rem !important;
}

.slider-toggle-btn {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3rem;
  background: #000;
  border-radius: 10px 10px 0 0;
  padding: 1rem 0;
  box-sizing: border-box;
  text-align: center;
  z-index: 3;
}

.slider-toggle-btn img {
  max-width: 100%;
  max-height: 100%;
}

.slider-toggle-container.active {
  top: 200px;
  overflow-y: auto;
}

.slider-toggle-container .neighborhood-score-container {
  padding-top: 3rem;
}

.slider-toggle-container .category-button-list .modern-dashboard-type-btn {
  padding: 0.35em 0.5em;
}

.slider-toggle-container .button-container button {
  color: #000;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
}

.slider-toggle-container .modern-summary-header-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem 0;
  width: 100%;
}

.slider-toggle-container .dasboard-action-buttons {
  color: #fff;
}

.slider-toggle-container .dasboard-action-buttons svg path {
  fill: #fff;
}

.modal-sidebar .scroll-bottom-icon {
  width: calc(365px - 2rem);
  display: flex;
  justify-content: center;
}

.modal-sidebar .scroll-bottom-icon,
.slider-toggle-container .scroll-bottom-icon {
  height: 40px;
  position: fixed;
  bottom: 6rem;
  z-index: 2;
  animation: bounce 2.5s infinite;
  pointer-events: none;
}

.modal-sidebar .scroll-bottom-icon svg,
.slider-toggle-container .scroll-bottom-icon svg {
  height: 40px;
  width: auto;
  border-radius: 50%;
  filter: drop-shadow(0 0 1.5px white);
  transform: rotate(-90deg);
  cursor: pointer;
  pointer-events: auto;
}

.modal-sidebar .scroll-bottom-icon svg rect,
.slider-toggle-container .scroll-bottom-icon svg rect {
  fill: #fff;
}

.modal-sidebar .scroll-bottom-icon svg path,
.slider-toggle-container .scroll-bottom-icon svg path {
  fill: #000;
}

.dashboard-address-name {
  font-weight: 500;
}

.dashboard-type-btn .feature-icon {
  height: 10px;
  width: auto;
}

.category-button-list
.modern-dashboard-type-btn
.modern-lifestyle-custom-button
.all-icon {
  height: 8px;
}

.smaller-screen-dashboard-btn {
  min-width: unset;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .tutorial-container,
  .change-address-container,
  .filter-search-container {
    font-size: 12px;
  }

  .dashboard-search-address-btn {
    font-size: 12px;
  }
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .category-button-list {
    justify-content: space-between;
    width: 100%;
  }
  .score-container {
    max-width: 310px;
  }

  .dashboard-header {
    flex-direction: column !important;
    align-items: center !important;
    gap: 0.5rem !important;
  }

  .dashboard-header img {
    width: 25px;
    height: auto;
  }

  .dashboard-button-container {
    position: fixed;
    bottom: var(--pb);
    left: 0;
    z-index: 99999;
    margin: var(--mt) 0 0 0;
    background: #fff;
  }

  .dashboard-button-container button {
    font-size: 12px;
  }

  .dashboard-summary-details {
    margin-bottom: 2rem;
  }

  .modern-dashboard-button-container {
    gap: 0 0.5rem;
  }

  .property-summary-details > div > * {
    font-size: 16px;
  }

  .dashboard-header img {
    width: 25px;
  }

  .property-summary-details img {
    width: 150px;
    max-height: 100px;
  }

  .dasboard-action-buttons {
    display: flex;
    gap: 0 0.5rem;
  }

  .slider-toggle-container .dasboard-action-buttons {
    position: absolute;
    top: 3.3rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem 0;
  }

  .modern-dasboard-action-buttons > .tutorial-container,
  .modern-dasboard-action-buttons > .change-address-container,
  .modern-dasboard-action-buttons > .filter-search-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: min-content;
  }

  .dashboard-search-address-btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .modern-dashboard-summary-details-header {
    padding-top: 0.5rem;
  }

  .modern-dashboard-summary-details-header
    > div
    > .dasboard-action-buttons
    svg
    path {
    fill: #fff;
  }

  .modern-dashboard-summary-details-header .modern-summary-header-buttons {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 1rem 0;
    padding: 0 0.2rem;
  }

  .modern-summary-header-buttons .category-button-list {
    gap: 0.5rem;
  }

  .dashboard-search-address-input {
    flex-direction: column;
    padding-bottom: 0 !important;
  }

  .dashboard-search-address-input > input {
    font-size: 16px !important;
  }

  .modern-summary-header-buttons .dasboard-action-buttons {
    gap: 1rem;
  }

  .modern-dashboard-walking-distance {
    gap: 0 1rem;
  }

  .iphone-dashboard-walking-distance {
    padding-bottom: 2rem;
  }

  .modal-sidebar .scroll-bottom-icon {
    width: 100%;
  }

  .slider-toggle-container .button-container {
    margin-bottom: 1rem;
  }

  .slider-toggle-container .iphone-button-container {
    margin-bottom: 13rem;
  }

  .mobile-dashboard-cotainer {
    height: auto;
  }

  .mobile-dashboard-content {
    background: #000;
  }

  .mobile-dashboard-content,
  .slider-toggle-container .button-container {
    width: 100%;
    width: -webkit-fill-available;
    width: -moz-available;
    display: flex;
    flex-direction: column;
  }

  .mobile-dashboard-content .category-button-list {
    width: unset;
    max-width: 100dvw;
    justify-content: space-evenly;
    padding: 1rem;
  }
}

@media screen and (min-width: 1440px) {
  .dashboard-summary-container {
    transform: scale(1.2);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(0);
  }
  70% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes expand {
  0% {
    transform: scale(0.9);
  }
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
