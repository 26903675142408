.skeleton-main-container {
  display: grid;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}

.skeleton-main-container span:first-of-type {
  width: 100%;
}
