.discover-neighborhood {
  display: flex;
  flex-direction: column;
  gap: 1em 1em;
  padding: 1.5em 2em;
  background: var(--color);
  font-size: 14px;
  width: 100%;
  color: var(--text-color);
  box-shadow: 3px 3px 17px 4px rgba(0,0,0,0.75);
}

.discover-back {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.discover-neighborhood > * {
  width: 100%;
}

.discover-title {
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 20px;
  font-weight: 800;
}

.discover-header-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1em;
}

.discover-header-container .summary-autocomplete {
  width: 90%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.5em;
  background: #ffffff;
  color: #000000;
  border: 2px solid #3D3D3D;
  border-radius: 5px;
  pointer-events: auto;
}

.discover-header-container .summary-autocomplete input {
  width: 100%;
}

.discover-header-container .summary-autocomplete input:focus {
  outline: none;
  border: none;
}

.discover-neighborhood .modern-walking-distance-container  {
  margin: 0;
  margin-top: 0.5em;
}

.discover-loading {
  display: flex;
  gap: 1em;
}

.discover-address {
    font-weight: 500;
    font-size: 18px;
}
  
.summary-parent-container {
    display: flex;
    flex-direction: column;
    border-bottom: 0.5px solid #B7B7B7;
    /* padding-bottom: 0.5em; */
    /* margin-bottom: 1em; */
}

.summary-parent-container:nth-child(3) {
  border: none;
}

.summary-parent-container h2 {
  font-size: 16px;
  font-weight: 500;
}
.gems-score-container,
.feel-score-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0 1em;
  margin: 1.25em 0 1em;
}

.gems-feature,
.feel-feature {
  display: flex;
  align-items: center;
}

.feel-feature.inactive-feature {
  opacity: 0.5;
  color: #999;
  filter: grayscale(100%);
}

.feel-feature h3, .gems-bar-container .bar-label {
  font-weight: 500;
}

.feel-feature span {
  font-size: 0.75em;
  width: 90%;
  word-break: keep-all;
}

.feel-details {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  width: 100%;
}

.feel-details > * {
  flex-grow: 1;
  flex-basis: 0; 
}

.feel-feature,
.gems-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  text-align: center;
}

.feel-feature {
  cursor: pointer;
}

.gems-bar-container svg path,
.feel-feature svg path {
  fill: rgba(var(--icon-color)) !important;
}

.gems-feature svg,
.feel-feature svg {
  width: 35px;
  height: 35px;
}

.gems-bar svg {
  width: 15px;
  height: 15px;
}

.discover-neighborhood .action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.gem-score-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em 0;
  width: 275px;
  position: relative;
}

.action-buttons button {
  flex: 0 0 200px;
  max-width: 200px;
  padding: 10px 15px;
  background-color: transparent;
  border: 1px solid var(--text-color);
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.discover-neighborhood .action-buttons button:hover {
  background: var(--text-color);
  color: var(--color);
}

/* PROGRESS BAR STYLING */
.bar-score-container {
  width: 100%;
  padding: 0;
}

.gems-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.75em;
  cursor: pointer;
}

.gems-bar {
  display: flex;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}

.bar-section {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  width: 100%;
  height: 35px;
  background: rgb(var(--icon-color));
  transition: all ease 0.3s;
}

.bar-section:hover {
  box-shadow: 1px 1px 25px 10px rgba(var(--icon-color), 0.4);
}

.bar-score {
  position: relative;
}

.total-score {
  text-align: center;
  padding-left: 10px;
  font-size: 18px;
}

.total-score span {
  font-size: 12px;
}

.gems-details {
  display: flex;
  margin-top: 10px;
}

.gems-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #333;
}

.icon-placeholder {
  margin-bottom: 5px;
}
