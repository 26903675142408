:root {
  --svg-color: var(--svg-color);
  --bg-icon: var(--bg-icon);
  --type-color: var(--type-color);
  --font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.max-w-screen-lg {
  max-width: 1050px;
  margin: 0 auto;
}

.main-layout {
  font-family: 'Montserrat', sans-serif;
}

@media screen and (max-width: 768px) {
  #root > div {
    width: 100%;
    overflow: hidden;
  }

  .max-w-screen-lg {
    max-width: 100%;
    margin: 0 auto;
  }
}
