.map-container {
    position: relative;
    height: 100vh;
    width: 100%;
  }
  
  .google-map {
    height: 100%;
    width: 100%;
  }
  
  .floating-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding-top: 2em;
    padding-right: 2em;
    text-align: center;
  }
  
  .floating-header select {
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;
  }

  .hamburger-menu {
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
    z-index: 999;
    border-radius: 5px;
    padding: 10px;
  }
  
  .sidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 350px;
    height: 100%;
    padding: 20px;
    transition: transform 0.3s ease;
    z-index: 998;
    background: #fff;
    border: 0;
    box-shadow: 0 1px 3px rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
    padding-top: 75px;
  }
  
  .sidebar.collapsed {
    transform: translateX(-350px);
  }
  
  .sidebar.open {
    transform: translateX(0);
  }