.loading-circle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: #555;
  animation: loading-spin 1s linear infinite;
}

@keyframes loading-spin {
  to {
    transform: rotate(360deg);
  }
}
