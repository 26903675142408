.Platform {
  text-align: center;
}

.platform-section {
  background: #fff;
  padding: 4rem 1rem;
}

.laptop-image {
  width: 100%;
  max-width: 800px;
  height: auto;
}

.what-americans-want {
  padding: 40px 20px 100px;
}

.what-americans-want p {
  max-width: 800px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 1.6;
  position: relative;
}

.what-americans-want .quote {
  font-size: 50px;
  vertical-align: top;
  line-height: 1;
}

.what-americans-want .quote:first-child {
  float: left;
  margin-right: 10px;
}

.what-americans-want .quote:last-child {
  float: right;
  margin-left: 10px;
}

.data-collection {
  position: relative;
  margin: 30px 0;
  padding: 40px 20px;
  background-image: url('../../assets/landing-page/bg-map.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.data-collection::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    360deg, #FFFFFF 0.06%, rgba(255, 255, 255, 0) 48.62%, rgba(255, 255, 255, 0.825) 97.18%
  );
  z-index: 1;
}

.data-collection > * {
  position: relative;
  z-index: 2;
}

.data-metrics {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  justify-items: center;
  align-items: center;
}

.metric {
  text-align: center;
}

.metric h2 {
  font-size: 36px;
  font-weight: 400;
  margin-top: auto;
  margin-bottom: 10px;
}

.metric p {
  font-size: 16px;
  color: #555;
}

.data-section {
  background: #333;
  color: white;
  padding: 40px 20px;
}

.data-section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-section img {
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
}

.data-section ul {
  list-style: none;
  padding: 0;
}

.data-section li {
  text-align: left;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.6;
}

.data-section ul {
  margin: 0;
  list-style-type: disc;
}

.more-features {
  background: #fff;
  padding: 40px 20px;
  margin: 2.5em auto;
}

.features-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  margin-bottom: 1.5em;
}

.feature-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem 0;
  text-align: center;
  margin-bottom: 2em;
}

.feature-item svg {
  width: auto;
  height: 50px;
  margin: auto 0;
}

.feature-item .rating-icon {
  height: 30px;
}

.feature-item .carbon-footprint-icon,
.feature-item .health-icon {
  height: 80px;
}

.feature-item .walk-score-icon,
.feature-item .navigation-icon,
.feature-item .white-label-icon,
.feature-item .customization-icon {
  height: 70px;
}

.feature-item .analytics-icon {
  height: 60px;
}

.feature-item h3 {
  font-weight: 400;
  margin: 0;
}

.feature-item p {
  font-size: 14px;
  color: #555;
  margin: 0;
}

.Platform .neighborhood-wrapper {
  position: relative;
  background: #333;
}

.Platform .neighborhood-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../../assets/landing-page/curly-line.svg') no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 0;
}

.Platform .neighborhood-wrapper section {
  position: relative;
  z-index: 1;
  background: transparent;
}

.Platform .neighborhood-wrapper h1,
.Platform .more-features h1 {
  margin-bottom: 3em;
}

@media (min-width: 768px) {
  .data-section-content {
    flex-direction: row;
    align-items: flex-start;
  }

  .neighborhood-wrapper .data-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .neighborhood-wrapper .data-section-content {
    gap: 0 2rem;
  }

  .neighborhood-wrapper .data-section:nth-of-type(odd) .data-section-content {
    flex-direction: row-reverse;
  }

  .neighborhood-wrapper .data-section:nth-of-type(odd) ul {
    margin-left: 1rem;
  }

  .neighborhood-wrapper img {
    max-width: 550px;
  }

  .data-section img {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .data-section ul {
    max-width: 400px;
  }
}

@media (max-width: 768px) {
  .Platform .neighborhood-wrapper h1,
  .Platform .more-features h1 {
    margin-bottom: 2em;
  }

  .metric h2 {
    font-size: 28px;
  }

  .data-section {
    padding: 40px;
  }

  .data-section ul {
    margin-left: 10px;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }
}
