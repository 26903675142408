.signup-container {
  position: relative;
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem 0;
  color: #000;
  background-image: url('../../assets/authentication-bg.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  font-family: var(--font-family);
}

.signup-overlay {
  width: inherit;
  height: inherit;
  position: absolute;
  background: #fff;
  opacity: 0.2;
  z-index: 1;
}

.signup-container .walkspan-logo {
  height: 80px;
  width: auto;
}

.signup-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem 0;
  background: #ffffff;
  padding: 2rem;
  border-radius: 5px;
  border: 1px solid rgba(241, 241, 241, 1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  z-index: 2;
}

.signup-card .title {
  font-size: 18px;
  font-weight: 500;
}

.signup-card,
.signup-container form {
  max-width: 350px;
}

.signup-container form {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
}

.signup-container form label {
  font-size: 14px;
}

.signup-container .input-container,
.signup-container .name-container > div {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0;
}

.signup-container .name-container {
  display: flex;
  gap: 0 0.5rem;
}

.signup-container
.input-container
.input-field {
  max-width: 350px;
  background: none;
  border: 1px solid #DADADA;
  border-radius: 5px;
  padding: 8px;
}

.signup-container
.name-container
.input-field {
  max-width: 154px;
}

.signup-container
.input-container
.input-field:focus {
  outline: none;
  border: 1px solid #000;
}

.signup-container .signup-actions {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #8A8A8A;
}

.signup-container
.signup-actions
.remember-me {
  display: flex;
  gap: 0 0.5rem;
}

.signup-container
.signup-actions
.remember-me
input[type="checkbox"] {
  accent-color: #1a181a;
}

.signup-container
.signup-actions
.forgot-password {
  cursor: pointer;
}

.signup-container button {
  color: #000;
  background: none;
  border: 1px solid #000;
  padding: 10px 50px;
  font-family: var(--font-family);
  cursor: pointer;
}

.signup-container .signup-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.signup-container
.signup-buttons
.submit-button {
  display: flex;
  justify-content: center;
  border: 1px solid transparent;
  background: #fe572a;
  color: #fff;
}

.no-code-msg {
  text-align: center;
}

.no-code-msg u {
  cursor: pointer;
  color: #fe572a;
}

@media screen and (max-width: 768px) {
  .signup-card, .signup-container form {
    max-width: 300px;
  }

  .signup-container .name-container {
    flex-direction: column;
    gap: 1rem 0;
  }

  .signup-container
  .name-container
  .input-field {
    max-width: 350px;
  }
}