h1, h2, h3, h4, h5, h6, p, ul, ol, li {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: inherit;
}

h1, h2, h3, h4, h5, h6 {
  display: block;
  font-weight: normal; /* Removes the bold styling */
}

* {
  box-sizing: border-box; /* Makes it easier to manage padding/margin */
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: var(--font-family);
  margin: 0;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  /* Adjust these values as needed for your responsive design */
  .desktop-sidebar {
    width: 0 !important;
  }

  .modal-content-fs,
  .smaller-modal-content-fs,
  .modal-with-sidebar {
    width: 100% !important;
  }

  .modal-map {
    height: 96dvh;
    width: 100%;
  }

  .iphone-modal-map {
    height: calc(100% + 1rem);
  }

  /* .modern-iphone-compare-modal,
  .modern-compare-modal {
    height: 64dvh !important;
  } */

  .show-address-container {
    height: 100dvh;
  }

  .modern-mobile-sidebar .collapse-btn {
    position: fixed;
    z-index: 3;
  }

  .modern-modal-sidebar .sidebar-top .modern-sidebar-header {
    margin-top: 2.2rem;
  }

  .iphone-mobile-sidebar
    .modern-modal-sidebar
    .sidebar-top
    .modern-sidebar-header {
    margin-top: 2.3rem;
  }

  .modern-mobile-modal {
    overflow-y: auto;
  }

  .mobile-markers-filter-wrapper {
    width: 100%;
    height: fit-content;
    position: absolute;
    bottom: -500px;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #000;
    color: #fff;
    transform: translateX(-50%) translateY(500px);
    z-index: 5;
    /* overflow-y: auto; */
    padding: 0 0 8pt;
    transition: all 0.5s ease;
  }

  .mobile-markers-filter-wrapper.open {
    bottom: 0;
    overflow: auto;
    max-height: calc(100dvh - 10pt);
  }

  .mobile-markers-filter-wrapper .toggle-btn {
    width: -webkit-fill-available;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color);
    padding: 14px 0;
  }

  .mobile-markers-filter-wrapper .filter-markers-header {
    width: -webkit-fill-available;
    padding: 0 8pt 14px;
    display: flex;
    justify-content: space-between;
  }

  .mobile-markers-filter-wrapper .filter-markers-header .feater-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .mobile-markers-filter-wrapper .other-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-size: 14px;
  }

  .mobile-markers-filter-wrapper .other-controls svg {
    height: 12px;
    width: auto;
  }

  .mobile-markers-filter-wrapper .other-controls .close-show-markers {
    height: 18px;
    width: auto;
  }

  .mobile-markers-filter-wrapper .other-controls > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4pt;
  }

  .mobile-markers-filter-wrapper .other-controls svg path {
    fill: #fff;
  }

  .mobile-markers-filter-wrapper .feater-wrapper svg path {
    fill: var(--tab-color);
  }

  .mobile-markers-filter-wrapper .feater-wrapper span {
    color: var(--tab-color);
  }

  .mobile-markers-filter-wrapper .search-input-container {
    margin: 0 1rem 1rem;
  }

  .mobile-markers-filter-wrapper .search-input-container,
  .mobile-markers-filter-wrapper .filter-container {
    width: -webkit-fill-available;
  }

  .mobile-markers-filter-wrapper .rating-filter-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
  }

  .mobile-markers-filter-wrapper .rating-filter-container > div {
    font-weight: 500;
  }

  .mobile-markers-filter-wrapper .rating-filter-header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid #fff;
    padding-bottom: 1rem;
  }

  .mobile-markers-filter-wrapper .rating-filter-header div:first-child {
    font-size: 12px;
  }

  .mobile-markers-filter-wrapper .rating-filter-header .title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 600;
  }

  .mobile-markers-filter-wrapper .filter-header {
    padding: 0 1rem !important;
  }

  .mobile-markers-filter-wrapper .rating-filter-wrapper {
    width: -webkit-fill-available;
    display: flex;
    justify-content: space-between;
    padding: 0 8pt 14px;
    font-size: 14px;
  }

  .mobile-markers-filter-wrapper .rating-buttons-wrapper {
    display: flex;
    gap: 0.8rem;
  }

  .mobile-markers-filter-wrapper .rating-buttons-wrapper .active-star path {
    fill: #fff;
  }

  .mobile-markers-filter-wrapper .rating-filter-wrapper svg {
    height: 20px;
    width: auto;
  }

  .mobile-markers-filter-wrapper .rating-filter-wrapper svg path {
    fill: #919191;
  }

  .mobile-markers-filter-wrapper .rating-filter-wrapper .clear-rating path {
    fill: none;
  }

  .mobile-markers-filter-wrapper .feature-markers-wrapper {
    height: auto;
    max-height: calc(100dvh - 90px);
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    gap: 1rem 0;
    overflow: auto;
    position: unset;
    background: unset;
    color: unset;
  }

  .mobile-markers-filter-wrapper .feature-markers-wrapper .marker-wrapper {
    background: #313131;
    display: grid;
    grid-template-columns: 100px 1fr;
    align-items: center;
    gap: 0 1rem;
  }

  .mobile-markers-filter-wrapper .marker-wrapper .marker-icon {
    height: 100%;
    background: var(--tab-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-markers-filter-wrapper .marker-wrapper .marker-icon svg path {
    fill: #fff;
  }

  .mobile-markers-filter-wrapper .marker-wrapper .establishment-distance svg path {
    fill: #fff;
  }

  .mobile-markers-filter-wrapper .marker-wrapper .distance {
    display: flex;
    align-items: baseline;
    gap: 0 0.5rem;
  }

  .mobile-markers-filter-wrapper .marker-wrapper .distance svg {
    height: 12px;
    width: auto;
  }

  .mobile-markers-filter-wrapper .marker-wrapper .distance svg path {
    fill: #fff;
  }

  .modal-content .place-card-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem 0;
    z-index: 3;
    backdrop-filter: blur(2px);
    background: rgba(0, 0, 0, 0.4);
  }

  .modal-content .place-card-wrapper .close-icon {
    position: absolute;
    top: 3rem;
    right: 2rem;
  }

  .modal-content .place-card-wrapper .card {
    max-width: 300px;
    background: #fff;
    border-radius: 10px;
  }

  .modal-content .place-card-wrapper .card img {
    height: 260px;
    width: 300px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .modal-content .place-card-wrapper .details {
    padding: 0.5rem;
  }

  .modal-content .place-card-wrapper .share-buttons {
    width: 300px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 1rem;
  }

  .modal-content .place-card-wrapper .share-buttons div {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 0.5rem;
    padding: 0.5rem;
    background: #000;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
  }

  .modal-content .place-card-wrapper .share-buttons svg {
    height: 16px;
    width: auto;
  }
}

body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  font-family: 'Montserrat', sans-serif;
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100dvh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  font-family: var(--font-family);
}

.smaller-modal {
  transform: scale(0.8);
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  display: flex;
  flex-wrap: wrap;
  font-family: inherit;
  width: 1250px;
  height: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  box-sizing: border-box;
}

.modal-fullscreen {
  width: 100%;
  height: 100dvh;
}

.modal-sidebar-fs {
  max-width: unset !important;
  width: 345px !important;
}

.hide-modal-sidebar-fs {
  width: 0;
}

.smaller-modal-sidebar-fs {
  width: 300px !important;
}

.modal-content-fs {
  width: 100% !important;
}

.modal-with-sidebar {
  width: 100%;
}

.smaller-modal-content-fs {
  width: calc(100% - 300px) !important;
}

.modal-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--light-color);
  width: 25%;
  max-width: 310px;
  height: 100%;
  box-sizing: border-box;
  padding: 1.5em 0 0 0;
  overflow-y: auto;
  overflow-x: hidden;
  user-select: none;
}

.modern-modal-sidebar {
  padding: 0;
}

.modern-modal-sidebar > * {
  margin: 0 !important;
}

.desktop-sidebar {
  width: 345px;
  height: 100%;
  overflow-y: auto;
  transition: all 0.2s ease;
}

.hide-desktop-sidebar {
  width: 0 !important;
}

.smaller-desktop-sidebar {
  width: 300px;
}

.modal-sidebar > * {
  margin-left: 0.75em;
  margin-right: 0.75em;
}

.modal-map {
  height: 100%;
  line-height: 1.4;
  background: #6d6565;
  transition: all 0.2s ease;
}

.no-background {
  background: none !important;
}

.close-modal {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.city-dropdown {
  outline: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  padding: 0.5em 35px 0.5em 35px;
  background: url('Vector.png') no-repeat left;
  background-position-x: 10px;
  background-color: white;
  background-size: 15px;
}

.address-input {
  border: none;
  outline: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  margin-top: 1em;
  padding: 0.5em 35px 0.5em 35px;
  background: url('Vector-1.png') no-repeat left;
  background-position-x: 10px;
  background-color: white;
  background-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.line-break {
  border: 1.5px solid #fff;
  margin-top: 1.5em;
}

.topWalkIndicators {
  width: 100%;
  font-weight: 800;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.botWalkIndicators {
  width: 100%;
  font-weight: 800;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.walking-distance-block {
  margin-top: 0.5em;
}
/* FOR SLIDER */
.slidecontainer {
  width: 95%;
}

/* The slider itself */
.slider,
.slider-variation {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: white;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Mouse-over effects */
.slider:hover,
.slider-variation:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url('slider-icon.png');
  background-size: contain;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url('slider-icon.png');
  background-size: contain;
  cursor: pointer;
}

.slider-variation::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url('slider-blue.svg');
  background-size: contain;
  cursor: pointer;
}

.slider-variation::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url('slider-blue.svg');
  background-size: contain;
  cursor: pointer;
}
/* END OF RANGE SLIDER */

.sidebar-button-container {
  margin-top: 1.5em;
}

.compare-button {
  font-family: inherit;
  background-color: var(--color);
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  border: none;
  cursor: pointer;
  padding: 0.75em;
  text-transform: uppercase;
  margin: 0.5em 0;
  width: 100%;
  border-radius: 1em;
}

.mobile-modal-sidebar .compare-button {
  margin-top: 1.5em;
}

.modern-compare-button {
  background-color: #fff;
  color: var(--color);
  border: 1px solid var(--color);
  border-radius: 0;
}
.dashboard-popup {
  z-index: 10000;
  background-color: #fff !important;
  width: 220px !important;
  color: #000000 !important;
  opacity: 1 !important;
  font-size: 12px !important;
}

.custom-btn-popup {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  z-index: 10000;
  background-color: #fff !important;
  color: var(--color) !important;
  opacity: 1 !important;
}

.dashboard-title {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1px;
  margin-bottom: 1.5em;
  font-weight: 600;
}

.sidebar-title {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0;
  background-size: 15px;
  /* padding-left: 20px; */
  text-align: left;
  font-weight: 800;
  letter-spacing: 2px;
  color: var(--title-color);
  margin-bottom: 1em;
}

.sidebar-title svg path {
  fill: var(--title-color);
}

.iphone-content {
  height: 100% !important;
}

.compare-address-popup {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  backdrop-filter: blur(2px);
  z-index: 6;
}

.compare-address-popup p {
  margin-bottom: 0;
}

.compare-address-popup .close-icon {
  position: absolute;
  right: 1rem;
  top: 1.4rem;
}

.compare-address-popup hr {
  color: #3E3E3E;
  border-color: #3E3E3E;
  background-color: #3E3E3E;
  width: 100%;
  margin-top: 0;
}

.compare-address-popup .property-locations-container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background: #000;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  margin: 0 1rem;
  padding: 0.5rem 1rem 1rem;
  box-shadow: 0px 0px 3px rgba(255, 255, 255, 0.5);
}

.compare-address-popup .lifestyle-search-address-input {
  color: #000000;
}

.compare-address-popup button {
  background: none;
  color: #fff;
  border: 1px solid #363636;
  padding: 0.5rem 0;
  margin: 0 8px;
}