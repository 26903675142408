.privacy-policy-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 5;
  background: #000;
  font-family: var(--font-family);
}

.privacy-policy-card {
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 2rem 0;
  background: #fff;
  padding: 3rem;
}

.privacy-policy-card .title {
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
  font-size: 24px;
  font-weight: 500;
}

.privacy-policy-card .title svg {
  height: 24px;
  width: auto;
}


.privacy-policy-card
.title
svg
path {
  stroke: #fe572a;
}

.privacy-policy-card
.title
svg
path:first-child {
  fill: #fe572a;
  stroke: unset;
}

.privacy-policy-card .content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0;
  line-height: 32px;
}

.privacy-policy-card .content p {
  margin: 0;
}

.privacy-policy-card button {
  color: #fff;
  background: #FE572A;
  border: none;
  padding: 0.6rem 3rem;
  font-family: var(--font-family);
  cursor: pointer;
  margin-left: auto;
}


@media screen and (max-width: 768px) {
  .privacy-policy-container {
    align-items: unset;
    overflow: auto;
  }

  .privacy-policy-card .content {
    text-align: justify;
    gap: 1rem 0;
    line-height: 28px;
  }

  .privacy-policy-card button {
    margin: 0 auto;
  }
}