.error-message-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.error-message-card {
  max-width: 450px;
  min-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem 0;
  background: #fff;
  padding: 2rem;
  margin: 0 1rem;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
  text-align: center;
}

.error-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #a6a6a6;
  z-index: 0;
  opacity: 0.7;
}

.error-message-card .logo {
  height: 80px;
  width: auto;
}

.error-message-card button {
  width: 100%;
  color: #fff;
  background: #fe572a;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 0;
  cursor: pointer;
}

.error-title {
  font-weight: 600;
  text-transform: uppercase;
}

.verification-code-container {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.verification-code-container input {
  width: 35px;
  height: 45px;
  background: #f1f1f1;
  border: 1px solid transparent;
  text-align: center;
}


.verification-code-container input:focus {
  outline: none;
  border: 1px solid #000;
}

.trial-mode-popup {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
  color: #000;
  background: #eae9f6;
  padding: 0.3rem;
  pointer-events: none;
  z-index: 1;
  opacity: 0.6;
  font-size: 10px;
}

.trial-mode-popup svg {
  width: 15px;
  height: auto;
}

.trial-mode-popup svg path {
  stroke: #2317a9;
}

.trial-mode-popup svg circle {
  stroke: #2317a9;
}

.trial-mode-popup svg circle:nth-child(2) {
  fill: #2317a9;
}

.form-error-message {
  display: flex;
  gap: 0 0.5rem;
  color: var(--error-text-color);
  background: #fff6f5;
  border: 1px solid #f29e9d;
  border-radius: 3px;
  font-size: 12px;
  padding: 0.5rem;
}

.form-error-message svg {
  width: 15px;
  height: auto;
}

.form-error-message svg path {
  stroke: #f29e9d;
}

.form-error-message svg circle {
  stroke: #f29e9d;
}

.form-error-message svg circle:nth-child(2) {
  fill: #f29e9d;
}

.score-icon-container {
  position: relative;
  width: var(--icon-width);
  height: auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.5em 0;
}

.score-icon-container .score-icon {
  width: var(--icon-width) !important;
  height: var(--icon-height) !important;
  margin: 0;
}

.score-icon-container .score-icon path {
  stroke: #3f3f3f;
  fill: none !important;
}

.score-icon-container .score-progress {
  position: absolute;
  top: 0px;
  left: -0.3px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.score-icon-container .score-progress .score-icon path {
  stroke: #fff;
}

.score-icon-container .score-progress[data-score-value='1'] {
  width: 50.5%;
  height: 39.5%;
}

.score-icon-container .score-progress[data-score-value='2'] {
  width: 50.5%;
  height: 100%;
}

.white-icon {
  height: var(--icon-height);
  width: var(--icon-width);
}

.white-icon .score-icon path {
  stroke: #fff;
}

.score-icon-container .score-content {
  position: absolute;
  height: inherit;
  font-size: var(--icon-content-font-size);
  z-index: 1;
}

.score-icon-container .gem-content {
  margin-bottom: 0.3rem;
}

.score-icon-container  .desktop-gem-content {
  margin-bottom: 1rem;
}

.white-icon .white-score-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: var(--icon-height);
  width: 100%;
  overflow: hidden;
}

.white-icon .white-score-progress[data-score-value='1'] {
  width: 50.5%;
  height: 39.5%;
}

.white-icon .white-score-progress[data-score-value='2'] {
  width: 50.5%;
  height: 100%;
}

.server-error-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem 0;
  background: #fff;
  z-index: 1;
  padding: 0 2rem;
  font-family: var(--font-family);
}

.server-error-container svg {
  height: 100px;
  width: auto;
}

.server-error-container button {
  color: #fff;
  background: #fe572a;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
}

.confirm-exit-popup {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 5;
}

.confirm-exit-popup .card {
  max-width: 90%;
  display: flex;
  flex-direction: column;
  gap: 16pt 0;
  background: #000;
  padding: 2rem;
  color: #fff;
  border-radius: 5px;
  text-align: center;
}

.confirm-exit-popup .card h2 {
  font-weight: 500;
  font-size: 16px;
}

.confirm-exit-popup .card .message {
  font-size: 14px;
}

.confirm-exit-popup .card .buttons {
  display: flex;
  flex-direction: column;
  gap: 8pt 0;
  font-size: 12px;
}

.confirm-exit-popup .card .buttons button {
  padding: 0.3rem;
  border: 1px solid transparent;
  font-size: 14px;
  border: 1px solid #ffffff;
}

.confirm-exit-popup .card .buttons button:nth-child(2) {
  border: 1px solid #363636;
  background: none;
  color: #fff;
}

.score-icon-v2 {
  position: relative;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
}

.score-icon-v2.current-score {
  color: #000000;
}

.score-icon-v2.current-score .description {
  color: #fff;
}

.score-icon-v2 svg {
  height: var(--icon-height);
  width: var(--icon-width);
}

.score-icon-v2 .overlay-icon {
  position: absolute;
  top: 0;
}

.score-icon-v2 span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: var(--icon-content-font-size);
}

.score-icon-v2.gem span {
  top: 42.5%;
}

.score-icon-v2 .overlay-icon path,
.score-icon-v2 .overlay-icon circle {
  fill: #454545;
}

.score-icon-v2.current-score svg:not(.overlay-icon) path,
.score-icon-v2.current-score svg:not(.overlay-icon) circle {
  fill: #FFFFFFA6;
}

.score-icon-v2.current-score .overlay-icon path,
.score-icon-v2.current-score .overlay-icon circle {
  fill: #ffffff;
}

.score-icon-v2 .overlay-icon[data-score-value='1'] path {
  clip-path: inset(0 50% 60% 0);
}

.score-icon-v2 .overlay-icon[data-score-value='1'] circle {
  clip-path: inset(0 50% 50% 0);
}

.score-icon-v2 .overlay-icon[data-score-value='2'] path,
.score-icon-v2 .overlay-icon[data-score-value='2'] circle {
  clip-path: inset(0 50% 0 0);
}

@media screen and (max-width: 768px) {
  .server-error-container h1 {
    font-size: 20px;
  }
}