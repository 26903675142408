.toggle-switch-container {
  border: 2px solid #fff;
  display: flex;
  background: #f0f0f0;
  border-radius: 10px;
  color: #fff;
  margin: 0 0 1em;
  padding: 0.25em;
}

.toggle-switch-container-mobile {
  margin: 0 auto;
}

.modern-toggle-switch-container {
  border: none;
  border-radius: 0;
  background: transparent;
  margin: 0;
}

.modern-toggle-switch-container button {
  color: #ffffff !important;
  border-radius: 0 !important;
  border: none !important;
}

.modern-toggle-switch-container .active-tab {
  color: var(--color) !important;
  background-color: #ffffff !important;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.full-width {
  width: 100%;
}

.toggle-switch-container button {
  font-family: inherit;
  background: none;
  border: 1px solid transparent;
  border-radius: 10px;
  color: #000000;
  padding: 0.7em;
  flex: 1 1 auto;
  font-size: 14px;
  cursor: pointer;
}

.active-tab {
  background-color: #fff !important;
  border: 1px solid #0f5671 !important;
  color: #000000 !important;
}

.smaller-font-size {
  font-size: 12px !important;
}
