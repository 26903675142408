.address-not-found-container {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  max-width: 600px;
  height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16pt 0;
  color: #2a2a2a;
  background: #fff;
  margin: 0 auto;
  padding: 0 10px;
  font-family: var(--font-family);
}

.address-not-found-container p {
  margin: 0;
}

.address-not-found-container .address-not-found-contact {
  font-size: 14px;
  font-weight: 500;
}

.address-not-found-container svg {
  height: 80px;
  width: auto;
}
