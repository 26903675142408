.get-started-container {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.get-started-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1050px;
  height: max-content;
  background: #fff;
  padding: 1.5em;
  margin: auto 12em;
  border-radius: 1em;
}

.get-started-card .close-icon {
  position: absolute;
  top: 16pt;
  right: 16pt;
  width: 20px;
  height: auto;
  cursor: pointer;
  z-index: 1;
}

.get-started-card .close-icon path {
  fill: #000000;
}

.get-started-slider {
  width: 100%;
  height: 100%;
}

.get-started-slider .slick-list,
.get-started-slider .slick-track,
.get-started-slider .slick-slide > div:first-child {
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
}

.get-started-slider .slick-dots {
  bottom: -2%;
}

.get-started-slider > .slick-dots > li > button {
  height: 10px;
  width: 10px;
}

.get-started-slider > .slick-dots > .slick-active > button {
  background-color: #000000;
  height: 15px;
  width: 15px;
}

.page-container {
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.page-title {
  color: var(--color);
  font-size: 20px !important;
  text-align: center !important;
}

.page-disclaimer {
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  gap: 0.5em 0;
  max-height: 375px;
  overflow-y: scroll;
}

.get-started-container .feature-type-container {
  width: 80%;
}

.score-guide-container .feature-type-container {
  width: 100%;
}

.feature-type-container > p {
  margin-top: 0.5em;
  margin-bottom: 0 !important;
}

.type-title {
  color: #4f4f4f;
  font-size: 20px;
  font-weight: 800;
}

.type-description {
  color: #000000;
  font-size: 14px;
}

.get-started-container .legends-container {
  display: flex;
  justify-content: space-around;
  gap: 0 1rem;
  margin: 1em 0 1.5em 0;
}

.get-started-container .legend-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0;
}

.get-started-container .legend-container > * {
  margin: 0 auto;
  text-align: center;
}

.standalone-legends-container {
  flex-wrap: wrap;
}

.get-started-container .score-wrapper {
  position: relative;
  width: 125px;
  height: auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.get-started-container .score-wrapper .icon-wrapper {
  position: relative;
  height: 50px;
}

.get-started-container .score-wrapper .icon-score-value {
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0.85);
}

.get-started-container .score-wrapper .feel-one path {
  transform: scale(0.5);
}

.get-started-container .score-wrapper .feel-two {
  left: 2px;
}

.get-started-container .score-wrapper svg {
  height: 50px;
  width: auto;
}

.get-started-container .score-wrapper svg:not(.icon-score-value) {
  fill: var(--color);
}

.get-started-container .score-wrapper svg:not(.icon-score-value) path {
  fill: var(--color);
}

.get-started-container .score-wrapper h1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
}

.get-started-container .score-description {
  font-size: 13px;
  color: #000000;
  padding: 0.3rem 0;
}

.get-started-container .score-radius {
  font-size: 12px;
  font-weight: 500;
}

.feature-divider {
  width: 100%;
  border-top: 1px solid #ccc;
  margin: 1px 0;
}

.lifestyle-container {
  width: 33%;
}

.standalone-lifestyle-container {
  width: 50%;
  margin-top: 1em;
}

.lifestyle-score h1 {
  top: 40%;
}

.get-started-container .score-wrapper .lifestyle-score svg {
  height: 50px;
  width: auto;
}

.get-started-container .score-wrapper .lifestyle-score .icon-score-value {
  transform: scale(0.8);
}

.lifestyle-score .gem-one {
  top: -1px;
  left: -1px;
}

.lifestyle-score .gem-one path {
  transform: scale(0.4);
}

.lifestyle-score .gem-two {
  top: -1.2px;
  left: 1px;
}

.lifestyle-score .gem-three {
  top: -0.5px;
  left: -1px;
  transform: scale(0.85);
}

.get-started-container .features-container {
  width: 80% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  margin: 0 auto !important;
  padding: 1em !important;
}

.features-header {
  color: #4f4f4f;
  margin-top: 0.5em;
  margin-bottom: 0 !important;
}

.get-started-container .features {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8pt 0;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 16pt;
}

.feature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border: none;
  border-radius: 50%;
  width: 165px;
}

.feature-container svg {
  width: auto;
  height: 30px;
}

.feature-container svg path {
  fill: var(--color);
}

.feature-container p {
  margin: 0;
}

.feature-label {
  font-weight: 600;
}

.feature-description {
  font-size: 14px;
  color: #000000;
  text-align: center;
}

.advanced-features-container {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  gap: 0em 0.5em;
  margin: 5em 0 0;
}

.map-feature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  border: 1px solid #efefef;
}

.map-feature-details {
  flex: 0 0 30%;
  background: #000000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1em;
}

.map-feature-details p {
  margin-bottom: 0;
}

.map-feature-label {
  font-size: 16px;
  font-weight: 800;
}

.map-feature-description {
  font-size: 14px;
  margin-top: 1em;
}

.map-feature-images-container {
  position: relative;
  flex: 0 0 40%;
  padding: 1em;
}

.map-feature-image {
  width: 100%;
}

.all-set-container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
  gap: 0.5em !important;
  padding: 7.5em 0 !important;
}

.all-set-images-container {
  display: flex;
  gap: 1em;
}

.all-set-images-container img {
  max-height: 320px;
  width: auto;
}

.all-set-container h2 {
  color: #000000;
  font-size: 30px;
  font-weight: 400;
}

.all-set-container button {
  border: 0;
  font-size: 16px;
  font-family: inherit;
  color: #fff;
  background: #fe572a;
  padding: 0.6em 2em;
  cursor: pointer;
}

.all-set-slogan-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0 0;
}

.all-set-slogan-container p {
  font-size: 20px;
}

.hide-content {
  height: 80vh;
  justify-content: flex-start !important;
}

.walkspan-text {
  color: #fe572a;
}

.get-started-container .v2-card {
  padding: 0;
  background: none;
}

.get-started-card .v2-slide {
  height: 500px;
  display: flex !important;
  gap: 0 8pt;
  border-radius: 10px;
  background: #fff;
}

.get-started-card .v2-slide.last {
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16pt 0;
  background-image: url('../../assets/bg.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 4rem;
}

.get-started-card .v2-slide.last > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4pt;
}

.get-started-card .v2-slide.last > div div:first-child {
  font-size: 24px;
}

.get-started-card .v2-slide.last span {
  color: #fe572a;
}

.get-started-card .v2-slide.last .get-started-btn {
  color: #fff;
  background: #fe572a;
  border-radius: 5px;
  padding: 4pt 16pt;
  cursor: pointer;
}

.get-started-card .v2-slide .left-panel {
  position: relative;
  width: 310px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  background: #212121;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.get-started-card .v2-slide .right-panel {
  max-width: 555px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.get-started-card .v2-slide .right-panel .title {
  font-size: 20px;
  margin: 24pt 0 16pt;
}

.get-started-card .v2-slide .right-panel .content-title {
  margin-bottom: 4pt;
  font-size: 18px;
  font-weight: 600;
}

.get-started-card .v2-slide .right-panel .content {
  max-width: 95%;
  font-size: 16px;
}

.get-started-card .v2-slide .left-panel,
.get-started-card .v2-slide .right-panel {
  padding: 16pt;
}

.get-started-card .v2-slide .left-panel .footer,
.get-started-card .v2-slide .right-panel .footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-top: auto;
}

.get-started-card .v2-slide .left-panel .footer {
  position: absolute;
  bottom: 8pt;
  padding: 0 16pt;
}

.get-started-card .v2-slide .left-panel .footer svg {
  height: 55px;
  width: auto;
}

.get-started-card .v2-slide .left-panel .footer div,
.get-started-card .v2-slide .right-panel .footer .next-btn {
  margin-left: auto;
}

.get-started-card .v2-slide .right-panel .footer > * {
  cursor: pointer;
}

.get-started-card .v2-slide .left-panel .left-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8pt;
  margin: auto 0;
  font-size: 14px;
}

.get-started-card .v2-slide .left-panel .left-content svg {
  width: 300px;
  height: auto;
}

.get-started-card .v2-slide .left-panel .left-content img {
  width: 250px;
  height: auto;
}

.get-started-card .v2-slide .score-legend-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4pt;
  margin-top: 16pt;
}

.get-started-card .v2-slide .score-legend-wrapper .score-legend-item {
  max-width: 95%;
  display: grid;
  grid-template-columns: 50px 100px 250px auto;
}

.get-started-card .v2-slide .score-legend-wrapper .score-legend-item:not(:last-child) {
  margin-bottom: 4pt;
}

.get-started-card .v2-slide .advanced-features-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8pt;
}

@media screen and (max-width: 768px) {
  .get-started-container {
    overflow-y: auto;
    top: 0;
    height: 100svh;
  }

  .get-started-container .page-disclaimer {
    max-height: fit-content;
  }

  .page-container {
    padding-top: 2em;
  }

  .score-guide-container {
    top: 0;
    overflow: hidden;
  }

  .score-guide-container .get-started-card {
    top: 50%;
    transform: scale(0.8) translateY(-50%);
    border-radius: 10px;
  }

  .score-guide-container .page-container {
    padding: 0;
  }

  .score-guide-container .feature-type-container {
    width: 100%;
  }

  .score-guide-container .type-title {
    display: flex;
    justify-content: center;
    color: #211F1F;
  }

  .score-guide-container .score-label {
    font-size: 14px;
  }

  .score-guide-container .legends-container {
    flex-direction: row;
    gap: 0 0.5rem;
  }

  .score-guide-container .score-description {
    font-size: 12px;
  }

  .page-title {
    font-size: 22px;
  }

  .get-started-card {
    height: 100%;
    max-width: 100%;
    border-radius: 0;
    margin: 0 auto;
  }

  .legends-container {
    width: -webkit-fill-available;
  }

  .lifestyle-container {
    width: 100%;
  }

  .type-description {
    text-align: justify;
  }

  .features-container {
    padding-top: 2em !important;
  }

  .features-header {
    margin: 0;
  }

  .get-started-container .features {
    margin-top: 0;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }

  .map-features-container {
    width: 80%;
    display: flex;
    padding: 2em 0 !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
  }

  .advanced-features-container {
    margin: 0;
  }

  .map-features-container > div {
    width: 100% !important;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem 0;
    padding: 2em 0 !important;
  }

  .map-feature {
    width: 60%;
    flex-direction: column;
  }

  .map-feature-image {
    width: 100%;
    width: -webkit-fill-available;
    width: -moz-available;
  }

  .map-feature-details,
  .all-set-slogan-container {
    text-align: center;
  }

  .all-set-container {
    padding: 10em 0 !important;
  }

  .all-set-slogan-container h2 {
    font-size: 25px;
  }

  .all-set-images-container img {
    max-height: 220px;
  }

  .all-set-container button {
    font-size: 18px;
  }

  .get-started-slider .slick-dots {
    bottom: 99%;
    top: -5px;
  }

  .get-started-slider .slick-list,
  .get-started-slider .slick-track,
  .get-started-slider .slick-slide > div:first-child {
    max-height: unset;
  }

  .get-started-card .v2-slide {
    height: 100svh;
    flex-direction: column;
  }

  .get-started-card .close-icon path {
    fill: #ffffff;
  }

  .get-started-card .v2-slide .left-panel {
    width: -webkit-fill-available;
    border-radius: 0;
  }

  .get-started-card .v2-slide .right-panel {
    height: -webkit-fill-available;
    max-width: 100svw;
    border-radius: 0;
    overflow: hidden;
  }

  .get-started-card .v2-slide .right-panel .slick-slider {
    height: calc(100% - 22px);
    padding-bottom: 16px;
  }

  .get-started-card .v2-slide .right-panel .title {
    margin: 0 0 24pt;
  }

  .get-started-card .v2-slide.last {
    width: 100svw;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
  }

  .get-started-card .v2-slide .score-legend-wrapper .score-legend-item {
    grid-template-columns: 50px 40px 210px auto;
  }
}
