.propoert-details-container {
  position: relative;
  margin-bottom: 0.5em !important;
}

.img-wrapper {
  position: absolute;
  z-index: 50;
  top: -50px;
  text-align: center;
}

.property-img {
  width: 75px;
  height: 75px;
  margin-left: 0.5em;
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.message-container {
  margin-top: 0.5em;
  display: flex;
  justify-content: end;
}

.hidden-message-container {
  visibility: hidden;
}

.propoert-details-container .message {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color);
  border-radius: 50%;
  cursor: pointer;
  padding: 0.25em;
}

.propoert-details-container .message > img {
  margin: 6px;
  height: 15px;
  width: 15x;
}

.details-container {
  color: var(--color);
  font-size: 14px;
}

.details-container > p {
  margin: 0.5em 0 0 0;
}

.other-details {
  display: flex;
  justify-content: space-between;
}

.other-details > div {
  margin: 0.3em 0;
}

.proper-address {
  line-height: 20px;
  font-weight: bold;
}

.property-city {
  font-weight: 400;
  line-height: 17px;
}

.property-type {
  font-weight: 300;
  line-height: 14.88px;
}

.property-price {
  font-weight: 400;
  line-height: 18px;
}

.house-details {
  font-weight: 300;
  line-height: 14.88px;
}

.house-details > div {
  display: flex;
  align-items: center;
}

.house-details > div > img {
  height: 14px;
  width: 14px;
  margin: 0 0.3em;
}

.house-details-divider {
  font-size: 18px;
  line-height: 10px;
  margin: 0 0.3em;
}
