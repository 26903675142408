.neighbohood-tab-popup {
  max-width: 430px;
  max-height: 260px;
  color: #ffffff;
  background: #0D0D0DD9;
  border-radius: 10px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  font-family: var(--font-family);
  z-index: 10;
  background: rgba(13, 13, 13, 0.85);
}

.neighbohood-tab-popup .tabs-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0 16pt;
  padding: 8pt 16pt;
}

.neighbohood-tab-popup .tabs-wrapper .tab-item {
  font-size: 14px;
  font-weight: 300;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}

.neighbohood-tab-popup .tabs-wrapper .selected {
  font-weight: 600;
  border-bottom: 1px solid #ffffff;
}

.neighbohood-tab-popup .neighborhood-score-container {
  margin: 0 !important;
}

.neighbohood-tab-popup .score-wrapper {
  padding: 12pt 14pt;
}

.neighbohood-tab-popup .neighborhood-legend-label-container p {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}