.walkindx-buttons-container {
  position: relative !important;
}

.walkindx-buttons-container > * {
  display: flex;
}

.custom-speed-dial {
  position: absolute;
  left: -32px;
  top: -224px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 0.5em;
}

.modern-walkindx-buttons-container
.custom-speed-dial
.speed-dial-button
svg
path {
  fill: #000 !important;
  stroke: none !important;
}

.modern-walkindx-buttons-container
.custom-speed-dial
.active-speed-dial-button
svg
path {
  fill: #fff !important;
}

.iphone-custom-speed-dial {
  left: -28px;
  top: -225px;
}

.speed-dial-button {
  display: flex;
  background: #fff;
  padding: 0.5em;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.speed-dial-button svg {
  height: 20px;
  width: 20px;
}

.speed-dial-button svg path {
  fill: var(--color);
}

.active-speed-dial-button svg path {
  fill: #fff !important;
}

.speed-dial-btn-popup {
  z-index: 10000;
  background-color: #fff !important;
  width: max-content !important;
  color: #0f5671 !important;
  opacity: 1 !important;
  font-size: 12px !important;
  box-shadow: 0px 2.815999984741211px 14.079999923706055px 0px rgba(0, 0, 0, 0.15);
}