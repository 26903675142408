.email-gate-container {
  width: 100svw;
  height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
  z-index: 4;
}

.email-gate-card {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem 0;
  background: #ffffff;
  text-align: center;
  font-size: 22px;
  padding: 2rem;
  z-index: 1;
}

.email-gate-card form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  background-color: #1a1a1a;
  align-items: center;
  box-sizing: border-box;
  padding: 2rem;
}

.email-gate-container form input {
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 10px;
}

.email-gate-container form button {
  padding: 10px 20px;
  color: #fff;
  background: none;
  font-size: 16px;
  font-family: var(--font-family);
  cursor: pointer;
  border: 1px solid #fff;
}

.email-gate-container .form-error-message {
  width: fit-content;
  grid-column: 1;
  grid-row: 2;
}