.neighborhood-score-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem 0;
}

.neighborhood-score-container,
.neighborhood-score-container .score-description {
  color: var(--color);
}

.modern-neighborhood-score-container .neighborhood-score-title,
.modern-neighborhood-score-container .score-title {
  color: #fff;
}

.modern-neighborhood-score-container .score-description,
.modern-neighborhood-score-container .neighborhood-legend-label-container p {
  color: #ffffff;
}

.sidebar-neighborhood-score-container {
  margin: 0.5rem 0;
}

.neighborhood-score-container .score-title {
  font-size: 20px;
  font-weight: 800;
  display: flex;
  align-items: center;
  gap: 0 0.5em;
}

.neighborhood-score-container .score-description {
  text-align: center;
  font-size: 14px;
}

.neighborhood-score-title {
  font-size: 20px;
}

.score-lifestyle h1 {
  margin-top: -0.25em;
}

.neighborhood-score-container .score-icon {
  position: relative;
  width: 125px;
  height: auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.5em 0;
}

.neighborhood-score-container .score-icon svg {
  width: 60%;
  height: 80px;
  fill: var(--color);
}

.neighborhood-score-container .score-icon svg path {
  fill: var(--color);
}

.score-icon h1 {
  position: absolute;
  font-size: 45px;
}

.sidebar-score-icon {
  width: 100px !important;
}

.modern-neighborhood-score-container .sidebar-score-icon svg {
  height: 80px !important;
}

.neighborhood-score-container .score-icon h1 {
  font-weight: 500;
}

.modern-neighborhood-score-container .score-icon svg {
  height: 100px;
  width: auto;
}

.modern-neighborhood-score-container .score-icon svg path {
  fill: none;
  stroke: #3f3f3f;
}

.modern-neighborhood-score-container .score-title {
  font-weight: 400;
}

.neighborhood-score-container .neighborhood-legend-label-container p {
  font-size: 14px;
}

.dashboard-legend-label-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.score-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.score-circle::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #fff;
  border-color: transparent;
  box-sizing: border-box;
  transform: rotate(calc(-90deg * (1 - var(--score-value))));
}

.score-circle[data-score-value='1']::before {
  border-color: #fff transparent transparent transparent;
  transform: rotate(-45deg);
}

.score-circle[data-score-value='2']::before {
  border-color: #fff transparent transparent #fff;
}

.score-circle[data-score-value='3']::before {
  border-color: #fff;
}

.sidebar-score-circle {
  width: 80px !important;
  height: 80px !important;
}

.score-diamond svg path {
  fill: none !important;
  stroke: #fff !important;
}

.score-diamond {
  position: absolute;
  top: 0px;
  left: -0.3px;
  height: 100%;
  width: calc(100% + 1px);
  overflow: hidden;
}

.score-diamond[data-score-value='1'] {
  width: 50.5%;
  height: 39.5%;
}

.score-diamond[data-score-value='2'] {
  width: 50.5%;
  height: 100%;
}

.dashboard-score-legend-container {
  display: flex;
  gap: 0.6em;
  margin: 0.5em 0;
}

.modern-neighborhood-score-legend-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.5rem;
}

.modern-neighborhood-score-legend-container
  > .neighborhood-legend-label-container {
  display: flex;
  align-items: center;
}

.sidebar-score-legend-container .neighborhood-legend-label-container {
  gap: 0.2rem;
}

.neighborhood-legend-label-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.neighborhood-legend-label-container p {
  font-size: 16px;
  margin: 0 0 0 0.2em;
}

.small-score-icon {
  position: relative;
  width: 40px;
  height: 40px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.small-score-icon h1 {
  position: absolute;
  font-size: 14px;
}

.small-score-icon svg {
  width: 60%;
  height: 60%;
  fill: var(--color);
}

.small-score-icon svg path {
  fill: var(--color);
}

.small-score-lifestyle svg {
  width: 90% !important;
  height: 60% !important;
}

.small-score-lifestyle h1 {
  position: absolute;
  font-size: 16px;
  margin-bottom: 0.3em;
}

.modern-small-score-icon {
  position: relative;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
}

.modern-small-score-icon svg {
  width: 30px !important;
  height: 30px !important;
}

.modern-small-score-icon .score-diamond {
  top: 2px;
}

.modern-small-score-lifestyle {
  width: 30px;
  height: 30px;
}

.icon-score-cirlce::before {
  position: absolute;
  top: -1px;
  left: -2px;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border: 1.5px solid white;
  border-radius: 50%;
  transform: rotate(calc(-90deg * (1 - var(--score-value))));
}

.icon-score-cirlce[data-score-value='1']::before {
  border-color: #fff transparent transparent transparent;
  transform: rotate(-45deg);
}

.icon-score-cirlce[data-score-value='2']::before {
  border-color: #fff transparent transparent #fff;
}

.icon-score-cirlce[data-score-value='3']::before {
  border-color: #fff;
}

.legend-popup {
  z-index: 10000;
  background-color: #fff !important;
  width: max-content !important;
  color: var(--color) !important;
  opacity: 1 !important;
  font-size: 10px !important;
  box-shadow: 0px 2.815999984741211px 14.079999923706055px 0px
    rgba(0, 0, 0, 0.15);
}

.modern-neighborhood-score-container .score-current-tab svg {
  width: 20px;
  height: 20px;
}

.modern-neighborhood-score-container .score-current-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 0.5rem;
  color: #fff;
}

.modern-neighborhood-score-container .score-current-tab svg path {
  fill: #fff;
}

.tab-score-desciption {
  color: #fff;
  font-size: 12px;
  padding: 0.5rem;
}

.neighborhood-score-container .tab-score-desciption {
  position: relative;
}

.tab-score-desciption[data-border-style='1'] {
  border-top: 1.5px dotted var(--tab-color);
  border-bottom: 1.5px dotted var(--tab-color);
}

.tab-score-desciption[data-border-style='2'] {
  border-top: 1.5px dashed var(--tab-color);
  border-bottom: 1.5px dashed var(--tab-color);
}

.tab-score-desciption[data-border-style='3'] {
  border-top: 1.5px solid var(--tab-color);
  border-bottom: 1.5px solid var(--tab-color);
}

.total-establishment-container {
  font-size: 10px;
  color: #fff;
}

.total-establishment-value {
  color: var(--tab-color);
  font-weight: 800;
  text-decoration: underline;
  cursor: pointer;
}

.map-type-legends {
  width: 100%;
  display: flex;
  justify-content: space-around;
  color: #fff;
  margin-bottom: 0.5rem;
}

.map-type-legends .map-type-legend {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  padding: 0.3rem 0;
  text-align: center;
}

.map-type-legend svg {
  height: 25.5px;
  width: 60px;
}

.map-type-legend .feel-line-label {
  position: absolute;
}

.sidebar-neighborhood-score-container
.skeleton-main-container {
  align-items: center;
}

.version-2-wrapper {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0 0.5em;
}

.version-2-wrapper.gem {
  align-items: flex-end;
}

.version-2-wrapper .score-details {
  display: flex;
  flex-direction: column;
  gap: 4pt;
  color: #ffffff;
}

.version-2-wrapper .score-title {
  font-size: 16px;
}

.version-2-wrapper .score-value {
  font-size: 18px;
  font-weight: 600;
}

.version-2-wrapper .score-description {
  font-size: 12px;
  text-align: unset;
}

.version-2-wrapper .total-gems {
  display: flex;
  flex-direction: column;
  gap: 0.5em 0;
  align-items: center;
  color: #ffffff;
}

.version-2-wrapper .gem-count {
  font-weight: 600;
}

.version-2-wrapper .total-gems .tab-pill {
  background: var(--tab-color);
  border-radius: 10px;
  padding: calc(0.12rem - 1px) 0.75rem;
  border: 1px solid var(--tab-color);
  transition: all 0.3s ease-in-out;
}

.version-2-wrapper .total-gems .tab-pill:hover {
  background: transparent;
}

.version-2-wrapper .total-gems .view-all {
  padding: 0.5em 0.75em;
  background: #fff;
  color: #000;
  cursor: pointer;
  border-radius: 0.5em;
  width: 80px;
  text-transform: uppercase;
  font-size: 10px;
  margin-top: 0.5em;
}

.neighborhood-score-container .version-2-legends {
  width: 100%;
}

.version-2-wrapper .tab-score-desciption {
  font-size: 12px;
}

.version-2-wrapper .total-gems,
.version-2-wrapper .selected-feel-feature {
  min-width: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  text-align: center;
  color: #ffffff;
}

.version-2-wrapper .tab-score-desciption {
  position: relative;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4pt;
  padding: 0;
}

.version-2-wrapper .tab-score-desciption svg {
  position: absolute;
  height: inherit;
  width: auto;
}

.version-2-wrapper .score-icon-container {
  margin: 0;
}

.version-2-wrapper.gem .feature-icon {
  width: auto;
  max-width: 50px;
  height: 30px;
}

.version-2-wrapper .feature-icon {
  width: auto;
  max-width: 50px;
  height: 40px;
}

.version-2-wrapper .feature-icon path {
  fill: var(--tab-color)
}

.active-score-icon-wrapper {
  position: relative;
  max-height: 55px;
}

.active-score-icon-wrapper svg {
  height: 55px;
  width: 60px;
}

.active-score-icon-wrapper svg path:nth-child(2),
.overall-score-wrapper svg path:nth-child(2),
.overall-score-wrapper svg circle:nth-child(2) {
  stroke: none;
}

.active-score-icon-wrapper span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 28px;
  color: #000;
}

.active-score-icon-wrapper.gem span {
  transform: translate(-50%, -60%);
  margin-bottom: 0.5rem;
}

.version-2-legends .neighborhood-legend-label-container .modern-small-score-icon {
  margin: 0;
}

.version-2-legends .modern-small-score-icon {
  height: unset;
  width: unset;
  font-size: 12px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .legend-popup {
    width: 170px !important;
  }

  .tab-score-desciption {
    font-size: 10px;
  }

  .modern-neighborhood-score-legend-container {
    margin: 0;
  }

  .modern-small-score-icon {
    margin: 0;
  }

  .neighborhood-score-container .version-2-wrapper {
    align-items: center;
    gap: 0;
  }
}
