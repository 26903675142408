.compare-maps-container {
  width: -webkit-fill-available;
  width: -moz-available;
  height: 100%;
  display: flex;
  gap: 0 8pt;
}

.compare-maps-container .compare-address-list {
  height: 100%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 8pt 0;
}

.compare-maps-container .compare-address-list .input-wrapper {
  display: flex;
  flex-direction: column;
  background: #0D0D0DD9;
  border-radius: 10px;
  padding: 8pt 0 16pt;
}

.compare-maps-container .compare-address-list .graph-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8pt 0;
  color: #ffffff;
  background: #0D0D0DD9;
  border-radius: 10px;
  padding: 16pt;
  overflow: auto;
  height: 100%;
}

.hide-graph {
  display: none !important;
}

.compare-maps-container .maps-wrapper {
  display: grid;
  width: -webkit-fill-available;
  width: -moz-available;
  background: #ffffff;
}

.maps-wrapper.two-items {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
}

.maps-wrapper.three-items {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
}

.maps-wrapper.four-items {
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: repeat(2, 1fr);
}

.maps-wrapper.three-items > :first-child {
  grid-column: span 2;
}

.maps-wrapper > * {
  border: 1px solid #ccc;
}

.compare-maps-container .address-list {
  display: flex;
  flex-direction: column;
  padding: 0 16pt;
}

.compare-maps-container .address-list .compare-address-item {
  display: flex;
  flex-direction: column;
  color: #E0DEDE;
  gap: 8pt 0;
  padding: 16pt 8pt;
  border-bottom: 1px solid #1C1C1C;
}

.compare-maps-container .address-list .empty-address-item {
  background: #0D0D0D;
}

.compare-maps-container .address-list .address-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}

.address-item-header svg,
.address-wrapper svg {
    width: 18px;
    height: 15px;
    cursor: pointer;
}

.chart-legend svg path {
  fill: var(--tab-color);
  stroke: var(--tab-color);
}

.chart-legend svg {
  width: 15px;
  height: 15px;
}

.compare-maps-container .compare-address-input {
  width: 100%;
  max-width: 300px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 1rem;
  background: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
  margin: 0 auto;
}

.compare-maps-container .compare-address-input input {
  width: 100%;
  border: none;
  outline: none;
  color: #000;
}

.compare-maps-container .address-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.compare-maps-container .address-wrapper  span {
  max-width: 80%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.compare-maps-container .compare-address-item .scores-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.compare-maps-container
.compare-address-item
.scores-wrapper
.score-icon-container
.score-content {
  height: auto;
}

.compare-maps-container .scores-wrapper .score-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4pt 0;
  font-size: 10px;
}

.compare-maps-container .scores-wrapper .score-item svg:not(.feature-icon) {
  height: 30px;
  width: auto;
}

.compare-maps-container .scores-wrapper .feature-icon {
  height: 12px;
  width: auto;
}

.compare-maps-container .scores-wrapper .feature-icon path {
  fill: var(--feature-color);
}

.compare-maps-container .scores-wrapper .overall-score-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.compare-maps-container .overall-score-wrapper span {
  position: absolute;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
}

.compare-maps-container .overall-score-wrapper.gem span {
  top: 1.5pt;
}

.compare-maps-container .version-2-legends {
  padding: 16pt 16pt 0;
  color: #ffffff;
}

.compare-maps-container .version-2-legends .neighborhood-legend-label-container p {
  font-size: 12px;
}

.compare-maps-container .autocomplete-wrapper {
  max-width: unset;
  color: #000000;
  background: #ffffff;
  border-radius: 5px;
}

.compare-maps-container .lifestyle-block {
  margin: 1rem 0 0;
}

.gm-toggle-wrapper {
  display: flex;
  color: #ffffff;
  background: #000000;
  border: 1px solid #333333;
  border-radius: 10px;
  padding: 3pt;
}

.gm-toggle-wrapper button {
  background: none;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 4pt 8pt;
  font-size: 12px;
  font-family: var(--font-family);
  transition: all 0.5s ease;
}

.gm-toggle-wrapper .active {
  background: #28c719;
  border: 1px solid #ffffff;
}