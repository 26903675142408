.landing-page-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 450px;
  grid-gap: 10px;
  background: #fff;
}

.client-landing-page {
  grid-template-columns: none;
  color: #000 !important;
}

.search-address-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.carousel-container .slick-list {
 height: 100dvh !important;
 width: 220px;
}

.carousel-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.carousel-container img {
  height: 350px;
  width: 240px;
  object-fit: cover;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.carousel-left-panel,
.carousel-right-panel {
  height: 100dvh;
}

.modern-landing-page {
  transition: all 0.5s ease-in-out !important;
  font-family: var(--font-family);
}

.search-address-container .landing-overlay {
  width: inherit;
  height: inherit;
  position: absolute;
  background: #fff;
  opacity: 0.2;
  z-index: 1;
}

.search-address-container .landing-bg {
  width: inherit;
  height: inherit;
  position: absolute;
  background-image: url('../../assets/bg.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.search-address-body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem 0;
  z-index: 1;
}

.search-address-body .search-container {
  width: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  z-index: 1;
  text-align: center;
}

.search-address-body .search-container .logo {
  width: auto;
  height: 130px;
}

.search-address-body .search-container .slogan {
  font-size: 24px;
}

.search-address-body .search-container .slogan-subtext b {
  color: #f1592a;
}

.search-address-body
.current-location-btn {
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
  color: #878787;
  background: #fff;
  border: 1px solid #878787;
  border-radius: 3px;
  padding: 0.25rem 0.5rem;
  font-size: 14px;
  cursor: pointer;
}

.search-address-body
.current-location-btn
svg {
  height: 14px;
  width: auto;
}

.modern-search-address-btn svg path {
  fill: #f1592a;
}

.modern-search-address-body h1 {
  font-size: 45px;
  line-height: normal;
}

.modern-search-address-body > .search-address-input {
  background: #fff;
  border: none;
}

.landing-page-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 2rem 1rem 0;
  z-index: 1;
}

.user-details-wrapper {
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
}

.search-address-container .hide-user {
  justify-content: end;
}

.user-details-wrapper
.user-icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fe572a;
}

.user-details-wrapper
.user-icon
svg {
  width: auto;
  height: 25px;
}

.landing-page-header
.user-details-wrapper
.logout-btn {
  color: #4f4f4f;
  font-size: 12px;
  cursor: pointer;
}

.landing-page-footer {
  position: relative;
  padding: 0 1rem 1rem;
  z-index: 1;
}

.landing-page-footer .disclaimer-btn {
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
  font-size: 14px;
  cursor: pointer;
}

.landing-page-footer .disclaimer-btn svg {
  width: auto;
  height: 16px;
}

.landing-title {
  font-weight: 500;
}

.walkspan-beta {
  font-size: 16px;
  margin-left: -8px;
}

.landing-description {
  margin-bottom: 0.5em;
  font-size: 26px;
  font-weight: 100;
}

.modern-slider-dots {
  position: absolute;
  top: 50%;
  left: 70px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.5rem 0;
  transform: translateY(-50%);
}

.modern-slider-line {
  position: absolute;
  top: 0;
  left: 4.5px;
  width: 1px;
  height: 100%;
  background-color: #fff;
  z-index: -1;
}

.modern-dot {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

.modern-dot:first-child {
  margin-top: 0;
}

.modern-dot:last-child {
  margin-bottom: 0;
}

.modern-dot.active {
  background-color: #f1592a;
}

.loading-search-address-container {
  opacity: 0.5;
}

.loaded-search-address-container {
  opacity: 1;
}

.search-address-input {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #fff;
  border: 1px solid #D1D1D1;
  border-radius: 3px;
  padding: 0.5em;
  outline: 0;
}

.search-address-input input {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  border: none;
  font-size: 16px;
  text-align: center;
}

.search-address-input input:focus {
  outline: none;
  border: none;
}

.search-address-input svg {
  width: 18px !important;
  height: 18px !important;
}

.search-address-input svg path {
  fill: #fff;
}

.search-address-btn {
  display: flex;
}

.search-address-btn svg path {
 fill: var(--color);
}

.prediction-container {
  position: absolute;
  left: 0;
  top: calc(100% + 3px);
  width: calc(100% - 1px);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0 0 5px 5px;
  z-index: 1000;
}

.modern-prediction-container {
  top: calc(100% + 1px);
}

.prediction {
  color: #000;
  padding: 6px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  font-size: 12px;
}

.prediction:last-child {
  border-bottom: none; /* Remove border from the last prediction */
}

.prediction:hover {
  background-color: #f0f0f0;
}

.view-tutorial-btn {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #f1592a;
  font-size: 16px;
  margin-top: 0.2rem;
  cursor: pointer;
}

.modern-view-tutorial-btn {
  background: #f1592a;
}

.view-tutorial-btn svg {
  height: 20px !important;
  width: auto !important;
}

@media screen and (max-width: 768px) {
  .mobile-landing-page-container {
    display: flex;
    flex-direction: column;
  }

  .landing-page-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .search-address-body
  .search-container
  .logo {
    width: 240px;
    height: auto;
  }

  .search-address-body
  .search-container
  .slogan {
    font-size: 18px;
  }

  .search-address-input {
    max-width: 350px;
  }

  .carousel-left-panel {
    height: calc(100dvh - 420px);
    width: 100%;
    max-width: 100dvw;
    overflow: hidden;
  }

  .carousel-left-panel .slick-list {
    width: 100dvw !important;
  }

  .carousel-left-panel img {
    width: 170px;
    height: 250px;
    object-fit: cover;
  }

  .view-tutorial-btn {
    font-size: 14px;
  }

  .view-tutorial-btn svg {
    height: 16px !important;
  }

  .search-address-body h1 {
    font-size: 28px;
  }

  .search-address-body .search-container {
    height: 100%;
    justify-content: end;
  }

  .search-address-body {
    height: calc((100% + 5rem) / 2);
    align-items: center;
    text-align: center;
    padding-top: 2rem;
    gap: 1rem 0;
  }

  .search-address-btn {
    justify-content: center;
  }

  .search-address-body h1,
  .modern-search-address-body h1 {
    font-size: 25px !important;
  }

  .search-address-body .landing-description,
  .modern-search-address-body .landing-description {
    font-size: 16px;
  }

  /* .search-address-body svg {
    width: 110px;
    height: 110px;
  } */

  .modern-slider-dots {
    top: -40px;
    left: 50%;
    height: 200px;
    padding: 2rem 0;
    transform: rotate(-90deg);
  }

  .landing-menu-btn,
  .sideber-menu-btn {
    position: absolute;
    width: 30px;
    height: auto;
  }

  .landing-menu-btn {
    left: 1rem;
    top: 5rem;
  }

  .sideber-menu-btn {
    right: 1rem;
    top: 5rem;
  }

  .landing-sidebar {
    position: absolute;
    left: -100dvw;
    height: 100dvh;
    width: 100%;
    max-width: 85dvw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 5rem 0;
    text-align: center;
    background: #fff;
    border-right: 1px solid #C0C0C0;
    padding: 4.5rem 0.5rem;
    z-index: 1;
    transition: all 0.5s ease;
  }

  .landing-sidebar.open {
    left: 0;
  }

  .user-details-wrapper {
    flex-direction: column;
    gap: 0.5rem 0;
  }

  .landing-sidebar-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem 0;
    color: #4F4F4F;
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 300;
  }
}


@keyframes rotateImages {
  0% {
    transform: translateX(-50%) rotate(0); /* Initial rotation angle */
  }
  100% {
    transform: translateX(-50%) rotate(360deg); /* Full rotation */
  }
}
