.rating-block {
  margin: 1.5em 0.5em;
}

.rating-value {
  display: flex;
  justify-content: center;
  gap: 0 0.5em;
}

.rating-indicator {
  color: white;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.rating-container {
  position: relative;
  display: flex;
  gap: 0 0.75em;
  padding: 2px 1em;
  min-width: 148px;
  /* background: #d9d9d9; */
}

.rating-container button {
  font-family: inherit;
}

.rating-button {
  position: absolute;
  top: -10px;
  left: 0;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background: #fff;
  font-weight: 800;
  padding: 0.25em 0.5em;
  font-size: 12px;
}

.rating-button:nth-child(1) {
  left: 10px;
}

.rating-button:nth-child(2) {
  left: 40px; /* Adjust this value to separate the buttons */
}

.rating-button:nth-child(3) {
  left: 70px; /* Adjust this value to separate the buttons */
}

.rating-button:nth-child(4) {
  left: 100px; /* Adjust this value to separate the buttons */
}

.rating-button:nth-child(5) {
  left: 130px; /* Adjust this value to separate the buttons */
}

.rating-button:nth-child(6) {
  left: 160px; /* Adjust this value to separate the buttons */
}


.mobile-rating-container {
  position: absolute;
  display: flex;
  justify-content: space-around;
  top: -50px;
  left: -78px;
  gap: 0 0.75em;
  padding: 5px 1em;
  width: max-content !important;
  background: #fff;
  height: 26px;
  border-radius: 10px;
}

.mobile-rating-container::before {
  content: '';
  position: absolute;
  top: 95%;
  left: 83px;
  border: 10px solid transparent;
  border-bottom-color: #fff;
  transform: rotate(180deg);
}

.mobile-compare-rating-container {
  position: absolute;
  display: flex;
  justify-content: space-around;
  top: -50px;
  left: 0;
  gap: 0 0.75em;
  padding: 5px 1em;
  min-width: max-content !important;
  background: #fff;
  height: 26px;
  border-radius: 10px;
}

.mobile-compare-rating-container::before {
  content: '';
  position: absolute;
  top: 95%;
  left: 7px;
  border: 10px solid transparent;
  border-bottom-color: #fff;
  transform: rotate(180deg);
}

.mobile-compare-rating-button {
  position: unset;
  padding: 0.7em;
}

.compare-rating-container {
  min-width: 140px;
}

.compare-rating-button {
  top: -9px !important;
  font-size: 10px !important;
}

.compare-rating-button:nth-child(1) {
  left: 10px;
}

.compare-rating-button:nth-child(2) {
  left: 35px; /* Adjust this value to separate the buttons */
}

.compare-rating-button:nth-child(3) {
  left: 60px; /* Adjust this value to separate the buttons */
}

.compare-rating-button:nth-child(4) {
  left: 85px; /* Adjust this value to separate the buttons */
}

.compare-rating-button:nth-child(5) {
  left: 110px; /* Adjust this value to separate the buttons */
}

.compare-rating-button:nth-child(6) {
  left: 135px; /* Adjust this value to separate the buttons */
}

.rate-item {
  box-shadow: none;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-size: medium;
  background: none;
  width: 16%;
  height: 40px;
  outline: none;
  border: 1px solid #fff;
  cursor: pointer;
}

.rate-item:hover {
  border: 1px solid transparent;
  background-color: white;
}

.active-rating {
  background-color: #114558;
  color: #fff;
}


@media screen and (max-width: 768px) {
  .rating-container {
    width: 140px;
  }

  .rating-button {
    font-size: 12px;
    top: -9px;
  }

  .rating-button:nth-child(1) {
    left: 10px;
  }

  .rating-button:nth-child(2) {
    left: 37px;
  }

  .rating-button:nth-child(3) {
    left: 64px;
  }

  .rating-button:nth-child(4) {
    left: 91px;
  }

  .rating-button:nth-child(5) {
    left: 118px;
  }

  .rating-button:nth-child(6) {
    left: 145px;
  }

  .mobile-rating-container > * {
    top: 7px;
    position: unset;
    padding: 0.25em 0.6em;
  }
}