.address-score-container {
  position: relative;
  height: 100%;
  width: 100%;
  font-family: var(--font-family);
  padding-bottom: 5rem;
}

.address-score-header {
  border-bottom: 1px solid #D0D0D0;
  font-size: 14px;
  padding: 1rem 0 0.1rem;
}

.address-score-header .tabs {
  width: 100%;
  display: flex;
}

.address-score-header .tabs div {
  padding: 0.5rem 1rem;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.address-score-header .tabs .selected,
.address-score-header .tabs div:hover {
  border-bottom: 2px solid #000;
}

.address-score-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem 1rem;
  margin: 3rem auto;
}

.address-score-body .search-address-input {
  width: unset;
}

.address-score-body .search-icon path {
  fill: #000;
}

.address-score-body .score-container {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
}

.address-score-body .neighborhood-score {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.address-score-body .neighborhood-score .skeleton-main-container {
  align-items: center;
  z-index: 0;
}

.address-score-body .neighborhood-score .feature-score {
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem 0;
  padding: 2rem 1rem;
  border: 1px solid #dadada;
}

.address-score-body .neighborhood-score .score-icon-wrapper {
  position: relative;
  height: 75px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}

.address-score-body .neighborhood-score .score-icon-wrapper.gem {
  width: 104px;
}

.address-score-body
.neighborhood-score
.score-icon-wrapper.gem
.value {
  position: relative;
  top: -5px;
}

.address-score-body .neighborhood-score .score-icon {
  height: 75px;
  width: inherit;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.score-icon[data-score-value='1'] {
  width: 50.5% !important;
  height: 50.5% !important;
}

.score-icon.gem[data-score-value='1'] {
  height: 31% !important;
}
.score-icon[data-score-value='2'] {
  width: 50.5% !important;
  height: 100%;
}

.address-score-body .neighborhood-score .label {
  font-size: 20px;
}

.address-score-body .neighborhood-score .description {
  text-align: center;
  color: #505050;
  font-size: 12px;
}

.address-score-body .neighborhood-score .no-data {
  margin: auto;
}

.address-score-body .score-container .buttons {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 1rem;
}

.address-score-body .score-container .buttons div {
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 0.5rem;
  border: 1px solid #dadada;
  font-size: 14px;
  padding: 0.3rem 0;
  cursor: pointer;
}

.address-score-body .score-container .heart-icon,
.address-score-body .score-container .rating-icon {
  height: 20px;
  width: auto;
}

.address-score-body .score-container .heart-icon path {
  stroke: #868686;
}

.address-score-body
.score-container
.heart-icon.saved-address
path {
  stroke: #eb082a;
}

.address-score-body .score-container .rating-icon path {
  fill: #868686;
}

.address-score-body .score-container .view-btn {
  color: #fff;
  background-color: #211F1F;
  padding: 0.8rem;
  text-align: center;
  cursor: pointer;
  margin-top: auto;
}

.address-score-body .location-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.address-score-body .location-container h3 {
  font-size: 18px;
  font-weight: 500;
}

.address-score-container .address-details-wrapper h3 {
  font-size: 22px;
  font-weight: 500;
}

.address-score-body .location-container .controls {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: auto;
}

.address-score-body .location-container svg {
  height: 15px;
  width: auto;
}

.address-score-body
.location-container
svg:not(.next-btn):not(.prev-btn)
path {
  fill: var(--type-color);
}

.address-score-body .location-container .feel-btns,
.address-score-body .location-container .gem-btns {
  position: relative;
  display: flex;
  gap: 0 1rem;
  border: 1px solid #C1C1C1;
  overflow: hidden;
  font-size: 12px;
  padding: 0 2rem;
  scroll-behavior: smooth;
}

.address-score-body
.location-container
.feel-btns
.feature-btn,
.address-score-body
.location-container
.gem-btns
.feature-btn {
  width: fit-content !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 0.35rem 0.5rem;
  margin: 0 auto;
  cursor: pointer;
}

.address-score-body
.location-container
.feature-btn.selected {
  color: #fff;
  background: #211f1f;
}

.address-score-body
.location-container
.slick-arrow {
  display: block !important;
}

.address-score-body
.location-container
.slick-prev {
  left: 0.5rem;
}

.address-score-body
.location-container
.slick-next {
  right: 0.5rem;
}

.address-score-body
.location-container
.slick-next:before,
.address-score-body
.location-container
.slick-prev:before {
  color: #000;
}

.address-score-body
.location-container
.walking-distance-container {
  position: absolute;
  bottom: 1.5rem;
  left: 1rem;
  margin: 0;
  padding: 0;
}

.address-score-body
.location-container
.dark-walking-distance-button::before {
  background: none;
}

.address-score-body
.location-container
.dark-walking-distance-button {
  border: 1px solid transparent;
}

.address-score-body
.location-container
.dark-selected-button::before {
  background: #fff;
}

.address-score-body .controls .arrow-btn {
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  background-color: #fff;
}

.address-score-body .controls .arrow-btn.next {
  right: 0;
}

.address-score-container .address-details-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem 0;
  margin: 0 auto;
}

.address-score-container .address-details-wrapper img {
  height: 100%;
  max-width: 375px;
  object-fit: cover;
}

.address-score-container .address-details-wrapper .details > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.address-score-container .address-details-wrapper .neighborhood {
  font-size: 18px;
}

.address-score-container .address-details-wrapper .description {
  line-height: 26px;
  text-align: justify;
}

.address-score-container .address-details-wrapper .view-overall {
  width: fit-content;
  text-decoration: underline;
  cursor: pointer;
}

.address-score-container .score-guide-container h3 {
  font-size: 16px;
}

.address-score-fav-container {
  margin: 3rem auto;
  border: 1px solid #d9d9d9;
  border-radius: 5px 5px 0 0;
  font-family: var(--font-family);
}

.address-score-fav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.address-score-fav-header .tabs {
  display: flex;
  color: #a6a6a6;
}

.address-score-fav-header .tabs div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid transparent;
  border-bottom: 0;
  padding: 0.8rem 1rem;
  cursor: pointer;
}

.address-score-fav-header .tabs .selected {
  color: #000;
  border: 1px solid #000;
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
}

.address-score-fav-header svg {
  height: 14px;
  width: auto;
  margin: 0 !important;
}

.address-score-fav-header .favorite-icon path {
  stroke: #a6a6a6;
}

.address-score-fav-header .selected .favorite-icon path {
  stroke: #000;
}

.address-score-fav-header .recently-viewed-icon {
  fill: #a6a6a6;
}

.address-score-fav-header .selected .recently-viewed-icon {
  fill: #000;
}

.address-score-fav-header .modern-walking-distance-container {
  margin: 0;
  padding: 0;
  max-height: 40px;
}

.address-score-fav-header
.modern-walking-distance-container
.buttons-container {
  transform: scale(0.6666);
}

.address-score-fav-container .dark-selected-button,
.address-score-fav-header .dark-selected-button {
  border: 1px solid #000;
}

.fav-table-container,
.fav-table-container table {
  width: 100%;
}

.fav-table-container table {
  border-collapse: collapse;
  border-spacing: 0;
  font-family: var(--font-family);
}

.fav-table-container {
  margin: 0 auto;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

.fav-table-container th, .fav-table-container td {
  padding: 15px;
  text-align: left;
}

.fav-table-container th {
  color: #fff;
  background: #211f1f;
  font-weight: 500;
}

.fav-table-container tr {
  border-bottom: 1px solid #D9D9D9;
  border-color: #D9D9D9;
}

.fav-table-container tr:last-child {
  border-bottom: none;
}

.fav-table-container .no-data {
  text-align: center;
}

.fav-table-container .score.gem .value {
  position: relative;
  top: -4px;
}

.fav-table-container .actions,
.fav-table-container .actions .btn {
  display: flex;
}

.fav-table-container .actions {
  gap: 1rem;
}

.fav-table-container .actions .btn {
  flex-direction: column;
  color: #505050;
  font-size: 12px;
  cursor: pointer;
}

.fav-table-container .actions svg {
  height: 30px;
  width: auto;
}

.fav-table-container .score svg {
  height: 45px;
  width: auto;
}

.fav-table-container .score-icon-wrapper {
  height: 45px;
  width: 45px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fav-table-container .score-icon-wrapper.gem {
  width: 63.24px;
}

.fav-table-container .score-icon {
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
}

.fav-table-container .score-icon svg ellipse,
.fav-table-container .score-icon svg path {
  stroke: #000;
}

@media screen and (max-width: 768px) {
  .address-score-header .tabs {
    justify-content: center;
  }

  .address-score-body,
  .address-score-body .neighborhood-score,
  .address-score-body .score-container .buttons,
  .address-score-body .location-container .controls,
  .address-score-container .address-details-wrapper .details {
    grid-template-columns: 1fr;
  }

  .address-score-body .score-container {
    max-width: 100%;
    margin: 0 auto;
  }

  .address-score-container .address-details-wrapper img {
    max-width: 100%;
  }

  .address-score-container .address-details-wrapper .details > div {
    text-align: center;
    gap: 1rem;
    padding: 1rem;
  }

  .address-score-body .location-container {
    padding: 1rem;
  }

  .address-score-body .location-container h3,
  .address-score-container .address-details-wrapper h3 {
    font-size: 20px;
    text-align: center;
  }

  .address-score-fav-container {
    border: 0;
    margin: 3rem 1rem 0;
  }

  .address-score-fav-container .modern-walking-distance-container {
    margin-top: 0;
    padding-top: 0;
  }

  .address-score-fav-header {
    border: 1px solid #d9d9d9;
    border-radius: 5px 5px 0 0;
    border-bottom: 0;
  }

  .address-score-fav-header .tabs {
    width: 100%;
  }

  .address-score-fav-header .tabs div {
    width: 50%;
    justify-content: center;
    font-size: 14px;
  }

  .fav-table-container {
    overflow-x: auto;
  }

  .fav-table-container tr:not(:last-child) {
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
  }

  .fav-table-container tr:last-child {
    border: 1px solid #d9d9d9;
    border-top: 0;
  }

  .fav-table-container th, .fav-table-container td {
    font-size: 14px;
    white-space: nowrap;
  }
}