.compare-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #ffffff;
  color: var(--color);
  overflow-y: auto;
}

.walk-index-container {
  display: grid;
  grid-template-columns: 225px calc(100% - 225px);
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 1em;
  padding-bottom: 1.5em;
  box-sizing: border-box;
}

.hide-first-child {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.walking-distance-location {
  height: 245px;
  padding: 0 1em;
}

.location-item {
  display: flex;
  flex-direction: column;
  padding: 1.25em 0;
  border-radius: 0.5em;
}

.location-item:nth-child(even) {
  background-color: #eaeaea;
}

.location-img {
  /* width: 160px; */
}

.compare-title {
  font-weight: 800;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.compare-type-container img,
.compare-type-container svg {
  width: 20px;
  height: 20px;
}

.compare-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em 0 1em;
  font-family: inherit;
}

.gem-compare-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  gap: 0 0.5rem;
  cursor: pointer;
}

.gem-compare-btn svg {
  height: 40px;
  width: auto;
  transform: scaleX(-1);
}

.compare-legend {
  height: 124.5px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  gap: 0.5em 0;
  font-family: inherit;
}

.modern-compare-legend .legend-item svg rect {
  rx: 0;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 0 1em;
}

.legend-item svg {
  width: 185px;
  height: 24px;
}

.legend-item svg rect {
  height: 20px;
}

.legend-item span {
  margin-top: 0.3rem;
}

.legend-item svg rect:nth-child(2) {
  fill: var(--color);
}

.legend-item:nth-child(3) svg rect {
  fill: var(--color);
}

.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  cursor: pointer;
}

.back-button svg rect {
  fill: var(--color);
}

/* BAR STYLES */
.compare-type-container {
  display: flex;
  gap: 0 1em;
  padding: 0.75em 0.5em;
  font-size: 15px;
  border-bottom: 1px solid #d9d9d9;
  box-sizing: border-box;
  height: 40px;
}

.compare-type-container svg path {
  fill: var(--type-color);
}

.index-score {
  width: 100%;
  object-fit: cover;
}

.index-score-loading {
  padding: 0.75em 1.25em;
  /* border-bottom: 1px solid #d9d9d9; */
  box-sizing: border-box;
  height: 40px;
  width: 100%;
}

/* BUTTON STYLING */
.btn-view {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color);
  padding: 0.75em 1em;
  color: #fff;
  gap: 0 1em;
  border: none;
  outline: none;
  margin: 0 1em;
  margin-top: 1em;
  cursor: pointer;
  border-radius: 0.5em;
  font-family: inherit;
  font-weight: 800;
}

.btn-view:disabled {
  opacity: 0.5;
  user-select: none;
  cursor: default;
}

.modern-btn-view {
  color: var(--color);
  background: #fff;
  border: 1px solid var(--color);
  border-radius: 0;
  padding: 0.8rem 1rem;
}

/* INPUT STYLING */
.location-item:nth-child(even) .location-input {
  background-color: #fff;
}

.location-input {
  margin: 1em 0;
  padding: 0.25em 0.25em;
  background-color: #eaeaea;
  /* border-radius: 2em; */
  display: flex;
  align-items: center;
  font-family: inherit;
}

.location-input input,
.location-select {
  width: 100%;
  border: none;
  background: none;
  outline: none;
  font-size: 16px !important;
  font-family: inherit;
}

.location-input img {
  width: 20px;
  margin-left: auto;
}

.location-property {
  font-size: 12px;
}

.walk-index-compare-mobile {
  display: none;
}

.score-type {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 0.75em 1.25em;
  border-bottom: 1px solid #d9d9d9;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
}

.score-type .score-type-value {
  /* position: absolute; */
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
}

.score-placeholder {
  display: none;
}

/* .rc-progress-line-path {
  stroke: var(--color);
} */

/* Responsive styles */
@media screen and (max-width: 768px) {
  .walk-index-container {
    grid-template-columns: 200px auto;
    display: none;
  }
  .walk-index-compare-mobile {
    display: inline;
  }

  .score-placeholder {
    display: flex;
    align-items: center;
    gap: 0 0.5em;
    font-size: 12px;
  }

  .score-placeholder svg {
    width: 15px;
    height: 15px;
  }

  .score-type,
  .index-score-loading {
    height: auto;
  }

  .index-score {
    object-fit: inherit;
  }

  .btn-view {
    margin-top: 1.5em;
  }

  .compare-header {
    align-items: center;
  }

  /* .compare-legend {
    transform: scale(0.8);
  } */

  .legend-item {
    font-size: 12px;
  }

  .legend-item img {
    width: 72px;
    height: 4.5px;
  }

  .back-button {
    font-size: 15px !important;
  }

  .back-button img {
    width: 44px !important;
    height: 44px !important;
  }

  .slider-container .slick-dots {
    top: 45%;
    bottom: 60%;
  }
}

.walkindx-compare-walking-distance {
  /* height: 245px; */
  margin: 0 1em 0 0;
}

.walkindx-compare-walking-distance > div > .walking-distance-container {
  margin: 0 0 1em;
}

.score-type-container {
  margin-top: 1em;
}
