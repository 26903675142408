.landing-nav {
  height: 80px;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: end;
  border-bottom: 1px solid #e2e2e2;
}

.landing-nav-buttons,
.landing-nav-socials {
  display: flex;
}

.landing-nav-buttons {
  color: #6c6c6c;
  font-size: 16px;
}

.landing-nav-buttons div {
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  font-family: Inter;
  font-weight: 300;
  text-transform: uppercase;
  height: fit-content;
}

.landing-nav-buttons div:hover {
  border-bottom: 2px solid #000;
}

.active-header {
  color: #000;
  border-bottom: 2px solid #000 !important;
  font-weight: 400 !important;
}

.landing-nav-socials {
  gap: 0 1rem;
  padding-bottom: 0.25rem;
}

.landing-nav-socials svg {
  height: 25px;
  width: auto;
}

.landing-nav > *:last-child {
  display: flex;
  gap: 0 1.5rem;
}

.landing-nav > *:last-child .login-btn {
  max-height: 30px;
  color: #fff;
  background: #fe572a;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  text-transform: capitalize;
}