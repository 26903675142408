.filter-container {
  padding: 0 1em;
}

.modern-filter-container {
  background: var(--color);
  padding: 0 0.5rem;
}

.filter-item-container {
  margin: 1em 0;
  color: #fff;
}

.modern-filter-item-container {
  margin: 0.75em 0;
}

.modern-filter-item-container .filter-header {
  width: auto;
  font-size: 14px;
  font-weight: 400 !important;
  padding: 0.8rem 1rem 0.5rem;
}

.modern-filter-item-container .filter-header svg path {
  fill: #fff;
}

.modern-filter-item-container .filter-header .close-filter-item {
  transform: scale(-1);
}

.modern-filter-container .category-list-wrapper {
  padding: 0 1rem 0.5rem;
}

.modern-filter-item-container .line-break {
  border: 1.5px solid var(--light-color);
  margin: 0;
}

.category-list-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5em;
  margin-top: 0.7rem;
}

.category-container {
  padding-top: 0.25em;
}

.checkbox-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0 0.5em;
  cursor: pointer;
}

.checkbox-item input {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.category-list {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-top: 0.5em;
  margin-left: 1em;
}

.filter-item-category {
  cursor: pointer;
}

.filter-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: #fff;
  padding: 0.5em;
  color: #0f5671; */
  gap: 0 1em;
  font-weight: 600;
  width: 100%;
}

.filter-icon {
  font-size: 20px;
  font-weight: 800;
  cursor: pointer;
}

.filter-header img {
  width: 15px;
  height: 15px;
}

.category-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  padding: 0.5em;
  text-align: center;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  text-transform: capitalize;
}

.is-category-selected {
  background: #fff;
  color: #0f5671;
}

.total-filter {
  border-radius: 50%;
  background: #0f5671;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 10px;
  margin-left: auto;
}

.clear-filter {
  background: #0f5671;
}

.category-total-wrapper {
  display: flex;
  gap: 0 0.5em;
  margin: 0.5em 0;
}
.filter-selected {
  color: #fff;
  border: 1px solid #fff;
  padding: 0.5em;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0 0.5em;
}

.filter-selected img {
  width: 15px;
}

.category-item:hover {
  background: #fff;
  color: #0f5671;
}

.filter-page-title {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  border: 1px solid #fff;
  border-radius: 0.75em;
  margin: 0.5em auto 1em;
}

.filter-walking-distance {
  margin-top: 1em;
}

.disabled-search {
  background-color: #ccc;
}

.disabled-search > * {
  cursor: not-allowed !important;
}

.filter-item {
  padding: 4pt;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: #fff; */
  color: #fff;
  /* border-radius: 3px; */
  font-size: 12px;
  cursor: pointer;
  width: -webkit-fill-available;
  width: -moz-available;
  user-select: none;
}

/* .filter-item:nth-child(-n+3) {
  border-bottom: 1px solid #5B5B5B;
}

.filter-item:nth-child(3n+1) {
  border-right: 1px solid #5B5B5B;
}

.filter-item:nth-child(3n) {
  border-left: 1px solid #5B5B5B;
}

.filter-item:nth-child(n+7) {
  border-top: 1px solid #5B5B5B;
} */

.filter-item {
  border-right: 1px solid #5B5B5B;
  border-bottom: 1px solid #5B5B5B;
}

.filter-item:nth-child(3n) {
  border-right: none;
}

.filter-item:nth-last-child(-n + 3) {
  /* border-bottom: none; */
}

.empty-filter-item {
  display:none;
}

.filter-item-icon {
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  font-weight: 800;
}

.filter-item-icon svg {
  width: auto;
  height: 18px;
}

.filter-item-icon svg path {
  stroke: #000;
}

.selected-filter-item {
  background: var(--tab-color);
  color: #fff;
}

.selected-filter-item svg path {
  stroke: #fff;
}

.modern-filter-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-weight: 600;
  padding: 1rem;
}

.modern-filter-header > div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.modern-filter-header-buttons > div {
  display: flex;
  align-items: self-end;
  gap: 0 1rem;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.modern-filter-header svg:not(.filter-close-icon) {
  width: 18px;
  height: auto;
  cursor: pointer;
}

.modern-filter-header svg path {
  fill: #fff;
}

.modern-search-input-container {
  border-radius: 5px;
  padding: 0.5rem;
}

.map-popups-wrapper .map-filter {
  overflow-y: auto;
  max-width: 430px;
}

.map-popups-wrapper .modern-filter-container {
  display: flex;
  flex-direction: column;
  gap: 16pt 0;
  background: #0D0D0DD9;
  border-radius: 10px;
  padding: 16pt;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
}

.map-popups-wrapper .modern-filter-container .modern-filter-header {
  font-weight: 500;
  padding: 0;
}

.map-popups-wrapper .modern-filter-container .map-rating-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 0 12pt;
}

.map-popups-wrapper
.modern-filter-container
.map-rating-filter
svg:not(.close-icon) {
  height: 20px;
  width: auto;
}

.map-popups-wrapper
.modern-filter-container
.map-rating-filter
svg:not(.close-icon)
path {
  fill: var(--star-color);
}

.map-popups-wrapper .modern-filter-item-container .filter-header {
  border-bottom: 2px solid #252525;
  padding: 0.8rem 1rem;
}

.modern-filter-container .category-list-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  background: #36363670;
  border: 1px solid #5B5B5B;
  border-radius: 4px;
  padding: 0;
  margin: 0.5rem 1rem;
}

.map-rating-filter .active-star path {
  fill: var(--star-color) !important;
}