.my-favorites-popup {
  position: relative;
  top: unset;
  left: unset;
  width: 100%;
  max-width: 430px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #fff;
  background: rgba(13, 13, 13, 0.85);
  border-radius: 10px;
  transform: unset;
  z-index: 4;
  padding-bottom: 16pt;
  height: 100%;
  overflow-y: auto;
}

.my-favorites-popup .favorite-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.my-favorites-popup .favorite-header .favorites-icon path:nth-child(1) {
  fill: none;
}

.my-favorites-popup .favorite-header .favorites-icon path:nth-child(2) {
  fill: #F27676;
  stroke: none;
}

.my-favorites-popup .favorite-header > div {
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
}

.my-favorites-popup .switch {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.my-favorites-popup .switch div {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1C1C1C;
  color: #EDEDED;
  font-size: 14px;
  font-weight: 200;
  padding: 0.5rem;
  transition: all 0.5s ease;
  cursor: pointer;
}

.my-favorites-popup .switch .active {
  color: #fff;
  background: #0D0D0D;
}

.my-favorites-popup .list {
  display: flex;
  flex-direction: column;
  gap: 0.2rem 0;
  overflow: auto;
  text-align: center;
}

.my-favorites-popup .empty {
  height: fit-content;
  align-items: center;
}

.my-favorites-popup .fav-marker-item,
.my-favorites-popup .loading-item {
  background: rgba(13, 13, 13, 0.5);
  border-bottom: 1px solid #1C1C1C;
  padding: 1rem;
}

.my-favorites-popup .loading-item {
  display: flex;
  flex-direction: column;
}

.my-favorites-popup .marker-details-icon path {
  fill: #fff;
}

.my-favorites-popup .fav-marker-item .category-image path {
  fill: #000;
}

.my-favorites-popup .fav-marker-item .popup-category-item {
  background: #fff;
  color: #000;
}

.my-favorites-popup .close-icon {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .my-favorites-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 0 2rem;
  }
}
