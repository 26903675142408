.planning-gradient {
  background-image: url('../../assets/landing-page/gradient-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.travel-gradient {
  background-image: url('../../assets/landing-page/gradient-bg3.png');
  background-size: cover;
  background-repeat: round;
}