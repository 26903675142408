.overlay-property {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute !important;
  background-color: rgba(0, 0, 0, 0.6);
}

.property-list-wrapper {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.compare-property-list-wrapper {
  align-items: normal !important;
}

.property-search-container {
  max-width: 500px;
  width: 70%;
}

.modern-property-search-container {
  background: #000;
  padding: 0.7rem 1rem 1rem;
  z-index: 1;
}

.property-search-container
.search-input-container {
  padding: 0.5rem;
}

.modern-property-search-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding-bottom: 0.5rem;
}

.modern-property-search-header h2 {
  line-height: normal;
  font-size: 16px;
  font-weight: 500;
}

.modern-property-search-header svg {
  height: 18px;
  width: auto;
  cursor: pointer;
}

.compare-property-search-container {
  margin-top: 6em !important;
}

.search-property-container {
  position: relative;
  width: 100%;
  z-index: 50;
  display: flex;
  flex-direction: column;
  gap: 1em 0;
}

.properties-container,
.city-container {
  position: absolute;
  top: 42px;
  width: 100%;
  max-height: 225px;
  background: #fff;
  overflow-y: auto;
}

.city-container {
  z-index: 999999;
}

.property-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75em 1em;
  gap: 0 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 12px;
  font-weight: 600;
  color: #808080;
  cursor: pointer;
}

.property-item:hover {
  background: #f3f3f3;
}

.is-disabled {
  opacity: 0.8;
  pointer-events: none;
}

.search-input-container {
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0.75em;
  box-sizing: border-box;
  gap: 0 0.5em;
  border-radius: 0.75em;
}

.search-input-container svg,
.search-input-container img {
  width: 15px;
  height: 15px;
}

.search-input-container svg path {
  fill: var(--color);
}

.search-clear {
  margin-left: auto;
}

.search-input-container input {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  width: 90%;
  font-family: inherit;
  text-overflow: ellipsis;
}

.badge {
  color: #fff;
  background-color: #28a745;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.empty-property {
  padding: 1em;
}


input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}