.address-search-input {
  color: #979797;
  border: solid #b3b3b3 1px;
  border-radius: 3px;
  padding: 0.8em;
  width: 90%;
  outline: 0;
  font-family: inherit;
}

@media screen and (max-width: 768px) {
  .location-select {
    font-size: 16px;
  }

  .address-search-input {
    font-size: 16px;
  }
}