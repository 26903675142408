.establisment-search-container {
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 9;
  width: 100%;
  height: 100dvh;
  background: rgba(0, 0, 0, 0.5);
}

.establisment-search-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  width: 500px;
  height: max-content;
  background: #fff;
  margin: auto 12em;
}

.modern-establisment-search-card {
  border-radius: 0;
  background: #000;
  filter: drop-shadow(0 0 1.5px white);
  padding: 0.7rem 1rem 1rem;
}

.modern-establisment-search-card .search-input-container {
  border-radius: 0;
  padding: 0.5rem;
}

.modern-establisment-search-card .result-wrapper,
.modern-establisment-search-card .no-result-wrapper {
  color: #fff;
}

.modern-establisment-search-card .result-wrapper button {
  color: #000;
  background: #fff;
}

.modern-establisment-search-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.modern-establisment-search-header h2 {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.modern-establisment-search-header svg {
  height: 18px;
  width: auto;
  cursor: pointer;
}

.search-establisment-btn {
  cursor: pointer;
}

.result-wrapper {
  width: 100%;
  margin-top: 1em;
}

.result-wrapper > div:not(.marker-list-wrapper) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8pt 0.75rem 0;
}

.result-wrapper button {
  height: fit-content;
  padding: 0.2rem 0.7rem;
  border: 0;
  background: var(--color);
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
}

.result-wrapper p,
.no-result-wrapper p {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  text-align: center;
}

.map-qs {
  width: 100%;
  max-width: 430px;
  background: #0D0D0DD9;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8pt 0;
  border-radius: 10px;
  pointer-events: auto;
  padding: 16pt 0;
  font-family: var(--font-family);
  z-index: 10;
  color: #fff;
  /* height: 100%; */
  overflow-y: auto;
}

.map-qs > div {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  margin: 0 16pt;
}

.map-qs .qs-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.map-qs .qs-header > div:first-child {
  display: flex;
  align-items: center;
  gap: 8pt;
}

.map-qs .input-wrapper {
  display: flex;
  align-items: center;
  gap: 8pt;
  background: #ffffff;
  border-radius: 5px;
  padding: 4pt;
}

.map-qs .input-wrapper input {
  flex-grow: 1;
  outline: none;
  border: none;
  color: #000;
}

.map-qs .input-wrapper svg {
  height: 16px;
  width: auto;
}

.map-qs .input-wrapper svg path {
  fill: #7E7E7E;
}

.marker-list-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 !important;
  overflow: auto;
}

.map-qs .num-result span {
  color: #919191;
  margin-left: 8pt;
}

.establisment-search-card .marker-list-wrapper {
  max-height: 400px;
}

.establisment-search-card .marker-list-wrapper .marker-item {
  width: -webkit-fill-available;
  width: -moz-available;
}

@media screen and (max-width: 768px) {
  .establisment-search-container {
    bottom: 0;
    height: fit-content;
    background: none;
  }

  .establisment-search-container.hide {
    visibility: hidden;
  }

  .establisment-search-card {
    min-width: calc(100% - 2rem);
    gap: 0.5rem 0;
    filter: unset;
    padding: 1rem;
    margin: 0;
  }

  .establisment-search-card .view-result {
    font-size: 12px;
    text-decoration: underline;
  }

  .modern-establisment-search-header h2 {
    font-size: 12px;
  }

  .modern-establisment-search-card .search-input-container {
    border-radius: 5px;
  }

  .no-result-wrapper {
    width: 100%;
  }

  .result-wrapper p, .no-result-wrapper p {
    margin-bottom: 1rem;
    text-align: left;
    font-size: 10px;
  }

  .result-wrapper button {
    width: 100%;
  }
}
