.sidebar-wrapper {
  position: relative;
}

.mobile-sidebar {
  position: absolute;
  top: 96%;
  bottom: 0;
  left: -0.2px;
  /* height: 4%; */
  color: white;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: left 0.3s ease;
  z-index: 999999;
  background: transparent;
  align-items: center;
  width: 100.5%;
  transition: top 0.3s ease;
}

.sidebar-content {
  padding: 20px;
}

.collapse-btn {
  padding: 10px;
  background-color: #000000;
  border: none;
  border-radius: 10px 10px 0px 0px;
  color: white;
  cursor: pointer;
  width: 100%;
  margin: 0 0 -1px;
}

.collapse-btn svg {
  animation: expand 2.5s infinite;
}

.close-sidebar-btn {
  position: absolute;
  top: 0;
}

.mobile-sidebar.open {
  top: 0%;
  overflow-y: auto;
}

.modern-mobile-sidebar {
  top: calc(100dvh - 4dvh);
}

.modern-compare-mobile-sidebar {
  top: calc(100dvh - 36dvh);
}

.modern-mobile-sidebar.open {
  top: 20%;
  height: auto;
  width: 100%;
}

.open .modal-sidebar-fs,
.mobile-sidebar .modal-sidebar-fs {
  width: 100% !important;
}

.open .modal-sidebar-fs {
  overflow-y: auto;
  overflow-x: hidden;
}

.mobile-sidebar .sidebar-top {
  min-height: auto;
}

.sidebar-top {
  box-sizing: content-box;
}

.modern-sidebar-header {
  height: 200px;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  background-image: url('../../assets/house-thumbnail.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modern-lifestyle-compare-header {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  color: #fff;
  background: #000;
}

.modern-lifestyle-compare-header svg {
  width: 35px;
  height: auto;
  cursor: pointer;
}

.modern-lifestyle-compare-header svg circle {
  fill: var(--color);
}

.modern-lifestyle-compare-header svg path {
  fill: #fff;
}

.modern-overlay-shadow {
  position: absolute;
  height: inherit;
  width: inherit;
  background: rgba(0, 0, 0, 0.3);
  pointer-events: none;
  z-index: 2;
}

.modern-sidebar-address-image {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: -1;
  object-fit: cover;
}

.modern-sidebar-header-content {
  position: absolute;
  top: 0;
  height: inherit;
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  padding: 0 0.8rem;
  z-index: 3;
  pointer-events: none;
}

.modern-sidebar-back-btn {
  height: 35px;
  width: 35px;
  margin-top: 1rem;
}

.modern-sidebar-back-btn circle {
  fill: #5e6060;
  fill-opacity: 1;
  cursor: pointer;
}

.modern-sidebar-back-btn path {
  fill: #fff;
}

.modern-sidebar-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 0.8em 0.5em;
  background: var(--color);
}

.modern-sidebar-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2rem 0;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}

.modern-sidebar-address-details {
  display: flex;
  align-items: end;
  padding-bottom: 2rem;
  justify-content: space-between;
}

.modern-sidebar-address-details > div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.modern-sidebar-address-details .modern-current-property {
  max-width: 70%;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

.modern-sidebar-address-details .modern-address-sublocality,
.lifestyle-compare-map-header .modern-address-sublocality {
  width: fit-content;
  font-size: 10px;
  background: #31a5f9;
  border-radius: 2px;
  padding: 0.1rem 0.3rem;
}

.modern-sidebar-footer {
  background: var(--color);
  margin-bottom: 66.79px !important;
  padding: 0 0.8rem 0.5rem;
}

.filter-back-btn-container {
  background: var(--color) !important;
}

.filter-back-btn-container > .sidebar-button-container {
  margin-top: 0 !important;
  margin-bottom: 1em !important;
}

.filter-back-btn-container > .sidebar-button-container > button {
  background: #ffffff !important;
  color: var(--color) !important;
}

.white-bg-button {
  background: #ffffff !important;
  color: var(--color) !important;
}

.divider {
  width: 100%;
  margin: 1.5em 0;
  border-bottom: #ffffff;
}

.hide-gradient-bg {
  height: auto !important;
}

.save-address-icon {
  cursor: pointer;
}

.modern-sidebar-back-btn,
.save-address-icon {
  pointer-events: auto;
}

.saved-address path {
  fill: #eb082a !important;
}

.modern-sidebar-filter-buttons {
  position: fixed;
  bottom: 0;
  min-width: var(--filter-btns-width);
  display: flex;
  justify-content: space-evenly;
  color: #fff;
  background: var(--light-color);
  border-top: 1px solid #d7d7d7;
  font-size: 12px;
  padding: 1.25rem 0;
  margin: 0 !important;
  z-index: 3;
}

.modern-sidebar-filter-buttons svg {
  width: 12px;
  height: auto;
}

.modern-sidebar-filter-buttons svg path {
  fill: #fff;
}

.modern-sidebar-filter-buttons .dropdown-icon {
  height: 5px;
  width: auto;
  transform: scaleY(-1);
}

.modern-sidebar-filter-buttons > div {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  background: var(--color);
  border: 1px #404040 solid;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  user-select: none;
}

.modern-sidebar-filter-buttons .opened-filter {
  color: var(--color);
  background: #fff;
}

.modern-sidebar-filter-buttons .opened-filter svg path {
  fill: var(--color);
}

.modern-sidebar-filter-buttons .opened-filter > .dropdown-icon {
  transform: scaleY(1);
}

.rating-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding-top: 0.5rem;
  margin: 0;
}

.rating-filter-container svg {
  cursor: pointer;
}

.rating-filter-container .rating-buttons-container {
  display: flex;
  gap: 1rem;
}

.rating-filter-container .rating-buttons-container .active-star path {
  fill: var(--star-color);
}

.rating-filter-container .rating-buttons-container svg {
  width: 20px;
  height: auto;
}

.rating-filter-container .rating-buttons-container svg path {
  fill: #919191;
}

.modern-combine-search-container {
  color: #fff;
  font-size: 10px;
  font-weight: 400;
  padding: 8pt 16pt 0;
}

.combine-search-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}

.combine-search-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  gap: 0.5rem 0;
  padding: 0.5rem;
  cursor: pointer;
}

.combine-search-btn.selected {
  background: #fff;
  color: #000;
}

.combine-search-btn > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.combine-search-btn svg {
  height: 20px;
  width: auto;
}

.combine-search-btn svg path {
  fill: var(--icon-color);
}

.toggle-button {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 23px;
}

.toggle-button input {
  opacity: 0;
  width: 0;
  height: 0;
}

.combine-search-slider {
  height: 100%;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #404040;
  border-radius: 34px;
  transition: 0.4s;
}

.combine-search-slider:before {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: #000;
  border-radius: 50%;
  transition: 0.2s;
}

.theme-slider {
  height: 100%;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #404040;
  border-radius: 34px;
  transition: 0.4s;
}

.theme-slider:before {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.2s;
}

.toggle-button input:checked + .theme-slider {
  background-color: #16cb23;
}

.toggle-button input:checked + .theme-slider:before {
  transform: translateX(26px);
}

.toggle-theme-wrapper {
  display: flex;
  justify-content: space-between;
}

.toggle-walkindx-container {
  height: auto;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}

.toggle-button input:checked + .combine-search-slider {
  background-color: #fff;
}

.toggle-button input:checked + .combine-search-slider:before {
  transform: translateX(26px);
}

.from-favorites-container {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  color: #fff;
  margin-top: 1.8rem;
}

.from-favorites-container span {
  font-size: 14px;
}

.from-favorites-select .select__control,
.from-favorites-select .select__menu {
  background: #1e1d1d;
}

.from-favorites-container .select__single-value,
.from-favorites-select .select__input-container {
  color: #fff;
}

.from-favorites-select .select__control {
  border: none;
  border-radius: 0;
}

.from-favorites-select .select__indicator-separator {
  display: none;
}

.sidebar-v2 {
  position: absolute;
  top: 0;
  left: -100dvw;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(0);
  z-index: 10;
  transition: all 0.5s ease;
}

.sidebar-v2.open {
  left: -1px;
  /* backdrop-filter: blur(2px); */
}


.sidebar-v2 .content {
  height: 100%;
  height: -webkit-fill-available;
  width: inherit;
  max-width: 335px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  background: #0D0D0DE5;
  padding: 16pt 1rem 1rem;
}

.sidebar-v2.light .content {
  color: #000;
  background: #fff;
}

.sidebar-v2 .buttons-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem 0;
}

.sidebar-v2 .view-tutorial {
  display: flex;
  align-items: center;
  gap: 0 1rem;
}

.sidebar-v2 .buttons-wrapper .header-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
}

.sidebar-v2 .buttons-wrapper .feature-btn {
  padding: 1rem;
  border-bottom: 1px solid #666666;
  cursor: pointer;
}

.sidebar-v2 .close-icon {
  height: 30px;
  width: auto;
  cursor: pointer;
}

.sidebar-v2.light .close-icon path {
  fill: #000;
}

.sidebar-v2 .view-tutorial svg {
  height: 21px;
  width: auto;
}

.sidebar-v2 .view-tutorial svg circle {
  stroke: #fff;
}

.sidebar-v2 .view-tutorial svg path {
  stroke: #fff;
}

.sidebar-v2.light .view-tutorial svg circle {
  stroke: #000;
}

.sidebar-v2.light .view-tutorial svg path {
  stroke: #000;
}

.sidebar-v2 .powered-by {
  font-size: 12px;
  display: flex;
  align-items: end;
  justify-content: center;
  gap: 0 1rem;
}

.sidebar-v2.light .logo path:nth-child(2) {
  fill: #000;
}

@media screen and (max-width: 768px) {
  .sidebar-v2 .close-icon {
    height: 20px;
  }

  .filter-back-btn-container {
    margin: 0 !important;
  }

  .mobile-modal-sidebar {
    height: auto;
    min-height: 100%;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
  }

  .modern-sidebar-footer {
    padding: 0 0.8rem 1rem;
  }

  .modern-sidebar-back-btn {
    margin-top: 3rem;
  }

  .modern-lifestyle-compare-header {
    justify-content: left;
    margin-top: 2rem;
  }

  .sidebar-button-container {
    margin-top: 0.5em;
  }

  .modern-sidebar-filter-buttons {
    position: unset;
    bottom: unset;
    min-width: unset;
    border: none;
    gap: 0 0.5rem;
  }

  .modern-sidebar-filter-buttons > div {
    width: 33%;
    justify-content: center;
    text-align: center;
  }
}
