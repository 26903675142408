.ContactUs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 5rem 1rem;
  background-color: #fff;
}

.contact-info {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  margin: 0 auto;
}

.contact-info p {
  font-size: 16px;
  margin-bottom: 20px;
}

.contact-info .message {
  color: #404040;
}

.contact-info .social-media svg {
  height: 20px;
  width: auto;
}

.contact-info .social-media svg path {
  fill: #000;
}

.contact-info .social-media .links {
  display: flex;
  max-width: 160px;
  padding: 1rem 0 0;
  gap: 0 0.5rem;
}

.contact-details {
  margin-bottom: 1rem;
}

.contact-details h2,
.social-media h2 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.contact-details p,
.social-media p {
  font-size: 16px;
}

.contact-details p {
  margin: 5px 0;
}

.contact-details svg {
  height: 20px;
  width: auto;
}

.ContactUs .form-group,
.contact-details p {
  display: flex;
  gap: 0 1rem;
}

.ContactUs .social-media a {
  margin-right: 10px;
}

.contact-form {
  background-color: #211f1f;
  padding: 20px;
  border-radius: 5px;
  color: white;
  margin: 0 auto;
}

.contact-form h1::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  border-bottom: 3px solid #fff;
  border-radius: 2px;
}

.ContactUs .form-group {
  margin-bottom: 20px;
}

.ContactUs .form-group input,
.ContactUs .form-group textarea {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  color: #fff;
  border: 1px solid #9b9b9b;
  background: #211f1f;
}

.ContactUs .form-group input:focus,
.ContactUs .form-group textarea:focus {
  outline: none;
  border: 1px solid #fff;
}

.ContactUs .form-group textarea {
  height: 100px;
}

.ContactUs button {
  width: 100%;
  padding: 10px;
  background-color: white;
  color: #333;
  border: none;
  margin-top: 10px;
  cursor: pointer;
}

.contact-us-submitted {
  max-width: 450px;
}

.contact-us-submitted h2,
.contact-us-submitted .submission-message {
  text-align: center;
}

.contact-us-submitted button {
  color: #fff;
  background: #fe572a;
}

.ContactUs button:hover {
  color: #000;
  background-color: #ddd;
}

.ContactUs .ty-noif svg {
  height: 25px;
  width: auto;
}

.ContactUs .ty-noif svg path:first-child {
  fill: #000000;
}


@media (max-width: 768px) {
  .ContactUs {
    grid-template-columns: 1fr;
  }

  .contact-info h1,
  .contact-details h2,
  .contact-info .message {
    text-align: center;
  }

  .ContactUs .contact-details,
  .ContactUs .social-media {
    margin: 0 auto 1rem;
  }

  .contact-info .social-media .links {
    justify-content: space-around;
  }

  .ContactUs .contact-form {
    min-width: -webkit-fill-available;
    min-width: -moz-available;
    min-width: fill-available;
  }

  .ContactUs .contact-form DIV:first-child input:first-child {
    margin-bottom: 20px;
  }

  .ContactUs .form-group {
    flex-direction: column;
  }
}