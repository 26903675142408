@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}

.fade-in {
  animation: fadeIn 0.3s ease-out forwards;
}

.fade-out {
  animation: fadeOut 0.3s ease-out forwards;
}

.zoom-in {
  animation: zoomIn 0.3s ease-out forwards;
}

.zoom-out {
  animation: zoomOut 0.3s ease-out forwards;
}
