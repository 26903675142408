.lifestyle-block {
  margin: 1.5em 0;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.lifestyle-text {
  background: url('walkicon.png') no-repeat left;
  background-size: 15px;
  padding-left: 20px;
  color: white;
  text-align: left;
  letter-spacing: 2px;
}

.scores-indicator {
  color: white;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  font-size: 12px;
  width: 100%;
}

.rating-text {
  margin-bottom: 0;
  background: url('rating.png') no-repeat left;
  background-size: 15px;
  padding-left: 20px;
  color: white;
  text-align: left;
  letter-spacing: 2px;
}

.rating-meter-pic {
  margin-left: 5%;
  width: 40%;
}

.lifestyle-value {
  color: white;
  font-weight: 800;
  display: flex;
}

.lifestyle-val-text {
  margin-top: 12%;
  /* flex: auto; */
  padding-left: 25px;
  /* justify-content: space-between; */
}

.compare-location-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.75em 0;
  margin: 1em 0 2em 0;
}

.modern-compare-location-container {
  width: auto;
  margin: 0;
  padding: 0 0.5rem;
}

.modern-compare-location-container .property-locations-container {
  margin-top: 0;
}

.lifestyle-search-address-input {
  width: 100%;
  max-width: 250px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 1rem;
  padding: 0.5rem;
  background: #e6e6e6;
}

.input-disable {
  filter: blur(1px);
}

.lifestyle-search-address-input input {
  flex-grow: 1;
  background: transparent;
  border: none;
  font-size: 14px;
}

.lifestyle-search-address-input input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.lifestyle-search-address-input input:focus {
  outline: none;
  border: none;
}

.lifestyle-search-address-input .prediction-container {
  width: calc(100% - 1.5px);
}

.property-location-item {
  display: flex;
  align-items: center;
  gap: 0 1em;
  font-weight: 800;
  color: #fff;
}

.modern-property-location-item {
  display: grid;
  grid-template-columns: 18px 1fr 20px;
  font-size: 14px;
  font-weight: 500;
}

.modern-property-location-item .add-to-favorites-icon {
  cursor: pointer;
}

.house-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.house-icon {
  position: relative;
  width: 40px;
}

.house-number {
  position: absolute;
  font-size: 12px;
  color: var(--color);
  width: auto;
  padding-bottom: 0.3rem;
}

.standalone-house-number {
  left: 25px;
}

.bar-icon {
  width: 100%;
  border-radius: 1em;
}

.value-container {
  display: flex;
  align-items: center;
}

.value-bar {
  height: 11px;
  min-width: 45px;
  display: flex;
  background: #ffffff;
  border: 0.5px solid #ffffff;
  border-radius: 4px;
  margin-right: 6px;
}

.meh-value {
  width: 25%;
  border-radius: 4px;
  background: var(--color);
}

.yay-value {
  width: 50%;
  border-radius: 4px;
  background: var(--color);
}

.wow-value {
  width: 100%;
  border-radius: 4px;
  background: var(--color);
}

.property-locations-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.address-container {
  width: 230px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  background: #ffffff;
  padding: 0.5em 1em 0.6em 1em;
}

.address-container > div > p {
  color: var(--color);
  margin: 0;
}

.address-container svg {
  width: 100%;
  border-radius: 1em;
}

.address-container svg rect:nth-child(2) {
  fill: var(--color);
}

.property-address {
  font-weight: 600;
  font-size: 11px;
}

.property-city {
  font-weight: 400;
  font-size: 13px;
}

.empty-address-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c1c1c1;
}

@media screen and (max-width: 768px) {
  .lifestyle-search-address-input input {
    font-size: 16px;
  }

  .lifestyle-search-address-input {
    max-width: 100%;
  }
}
