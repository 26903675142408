.header {
  display: none;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #ddd;
  position: relative;
  z-index: 1000;
  height: 75px;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  height: 100%;
}

.logo-mobile {
  width: 100px;
  cursor: pointer;
}

.menu-icon {
  font-size: 24px;
  cursor: pointer;
}

.menu-close {
  display: flex;
  justify-content: flex-end;
  padding: 0 1em;
  font-size: 24px;
  cursor: pointer;
}

.header-menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 60px;
  width: 100%;
  background-color: white;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: -1;
}

.header-menu.open {
  transform: translateY(0);
  z-index: 999;
}

.header-menu div {
  padding: 10px 20px;
  cursor: pointer;
  transition: color 0.3s;
  text-align: center;
}

.header-menu div:hover {
  color: #f1592a;
}

.header-menu .active {
  font-weight: bold;
  color: #f1592a;
}

.header-socials {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.header-socials a {
  margin: 0 10px;
}

.header-socials svg {
  width: 24px;
  height: 24px;
}

@media (max-width: 768px) {
  .header {
    display: block;
  }

  .landing-nav {
    display: none;
  }

  .menu-icon {
    display: block;
  }

  .header-menu {
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .header-menu.open {
    transform: translateY(75px);
  }

  .header-menu div {
    padding: 10px;
    width: 100%;
    text-align: center;
  }

  .header-socials {
    flex-direction: row;
    justify-content: center;
    margin: 10px 0;
  }

  .header .login-btn {
    max-height: 30px;
    color: #fff;
    background: #fe572a;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: capitalize;
    padding: 0.5rem 1.5rem;
    margin: 2rem 0 1rem;
  }
}
