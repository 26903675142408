@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

:root {
  --font-family: 'Montserrat', sans-serif;
}

.App {
  text-align: center;
  font-family: var(--font-family);
  color: var(--color);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input {
  font-family: var(--font-family);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.WalkingExperience {
  /* color: gray; */
  font-size: medium;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  width: 300px;
}

.boldtext {
  font-style: italic;
  font-weight: bold;
}

.disclaimer {
  font-style: italic;
}

.WalkingExperienceButton {
  padding: 20px;
  font-size: large;
  font-family: 'Montserrat', sans-serif;
  background: #0f5671;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}

.starTitle {
  margin-bottom: 5px;
}

.stars {
  margin: 0;
  padding: 0;
}

#modalContainer {
  display: none;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  /* Add any additional styling you want for the modal */
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1em;
  margin-right: 1em;
  color: #fff;
  font-weight: 800;
  cursor: pointer;
}

#modalContainer {
  display: none;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1em;
  margin-right: 1em;
  color: #fff;
  font-weight: 800;
  cursor: pointer;
}

.walking-experience {
  color: gray;
  font-size: medium;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 300px;
}

.bold-text {
  font-style: italic;
  font-weight: bold;
}

.walking-experience-button {
  position: relative;
  min-width: 200px;
  padding: 12px;
  font-size: 16px;
  font-family: 'GothamLight';
  background: #0f5671;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}

.beta-tag {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 10px;
  background-color: white;
  color: #000;
  padding: 0.25em 0.4em;
  font-weight: 800;
  border-radius: 0.25rem;
}

.white-bg {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: white;
}

.sharp-border {
  border-radius: 0 !important;
}

.pac-container {
  margin-top: 0.7rem;
}

.pac-item:hover {
  cursor: pointer;
}

.map-type-legends .map-type-legend svg path:nth-child(2),
.tab-score-desciption svg path:nth-child(2)
.tab-score-desciption svg path,
.map-type-legends .map-type-legend svg path,
.map-type-legend svg path {
  fill: var(--tab-color);
  stroke: var(--tab-color);
}
