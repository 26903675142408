.recent-history-popup {
  position: relative;
  top: unset;
  left: unset;
  width: 100%;
  max-width: 430px;
  display: flex;
  flex-direction: column;
  color: #fff;
  background: rgba(13, 13, 13, 0.85);
  border-radius: 10px;
  transform: unset;
  z-index: 4;
  padding-bottom: 16pt;
  height: 100%;
  overflow-y: auto;
}

.recent-history-popup .history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.recent-history-popup .history-header svg path {
  fill: #fff;
}

.recent-history-popup .history-header svg polygon {
  fill: #fff;
}

.recent-history-popup .history-header .recent-icon {
  height: 20px;
  width: auto;
}

.recent-history-popup .history-header > div {
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
}

.recent-history-popup .recent-address-item {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  gap: 0.3rem 0;
}

.recent-history-popup .recent-address-item,
.recent-history-popup .loading-item {
  background: rgba(13, 13, 13, 0.5);
  border-bottom: 1px solid #1C1C1C;
  padding: 1rem;
}

.recent-history-popup .loading-item {
  display: flex;
  flex-direction: column;
}


.recent-history-popup .recent-address-header,
.recent-history-popup .recent-address-details {
  display: grid;
  grid-template-columns: 150px 1fr 1fr;
  gap: 0 0.5rem;
}

.recent-history-popup .recent-address-header div {
  font-size: 10px;
}

.recent-history-popup .recent-address-header div:not(:first-child) {
  display: flex;
  justify-content: center;
}

.recent-history-popup .recent-address-details div {
  font-size: 12px;
}

.recent-address-details .score .score-content {
  color: rgba(13, 13, 13, 0.5);
  font-weight: 700;
}

.recent-history-popup .recent-address-details .score {
  display: flex;
  justify-content: center;
}

.recent-address-details .score .score-icon path {
  fill: #E0DEDE !important;
  stroke: none;
}

.recent-history-popup .list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem 0;
  overflow: auto;
}

.recent-history-popup .empty {
  height: fit-content;
  text-align: center;
}

.recent-history-popup .close-icon {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .recent-history-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 0 2rem;
    gap: 1rem;
    max-height: 300px;
  }

  .recent-history-popup .address-list-wrapper {
    overflow: auto;
  }
}