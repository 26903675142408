.fp-container {
  position: relative;
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem 0;
  color: #000;
  background-image: url('../../assets/authentication-bg.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  font-family: var(--font-family);
}

.fp-overlay {
  width: inherit;
  height: inherit;
  position: absolute;
  background: #fff;
  opacity: 0.2;
  z-index: 1;
}

.fp-container .walkspan-logo {
  height: 80px;
  width: auto;
}

.fp-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem 0;
  background: #ffffff;
  padding: 2rem;
  border-radius: 5px;
  border: 1px solid rgba(241, 241, 241, 1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  z-index: 2;
}


.fp-card .title {
  font-size: 18px;
  font-weight: 500;
}

.fp-card,
.fp-container form {
  max-width: 350px;
}

.fp-container form {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
}

.fp-container form label {
  font-size: 14px;
}

.fp-container .input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0;
}

.fp-container
.input-container
.input-field {
  max-width: 350px;
  background: none;
  border: 1px solid #DADADA;
  border-radius: 2px;
  padding: 8px;
}

.fp-container
.input-container
.input-field:focus {
  outline: none;
  border: 1px solid #000;
}

.no-code-msg {
  text-align: center;
}

.no-code-msg u {
  cursor: pointer;
  color: #fe572a;
}

.fp-container button {
  color: #000;
  background: none;
  border: 1px solid #000;
  padding: 10px 50px;
  font-family: var(--font-family);
  cursor: pointer;
}

.fp-container .fp-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.fp-container
.fp-buttons
.submit-button {
  display: flex;
  justify-content: center;
  border: 1px solid transparent;
  background: #fe572a;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .fp-card,
  .fp-container form {
    max-width: 300px;
  }
}