.About {
  text-align: center;
}

.who-we-are {
  background: #211f1f;
  color: white;
  padding-bottom: 2rem;
}

.who-we-are .lazy-load-image-loaded {
  width: 100%;
}

.who-we-are img {
  height: 100%;
  max-height: 440px;
  width: 100%;
  object-fit: cover;
}

.who-we-are .content {
  padding: 2em;
}

.who-we-are h1,
.what-we-do h1 {
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 1.5em;
}

.who-we-are-content {
  display: grid;
  gap: 1.5em;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  text-align: left;
  padding: 0 1.5em;
}

.who-we-are-item {
  border-radius: 5px;
}

.who-we-are-item p {
  color: #eaeaea;
  font-family: Inter;
  font-weight: 200;
  margin: 0;
}

.what-we-do {
  padding: 40px 20px;
}

.what-we-do p {
  margin: 0 auto;
  font-family: Inter;
  font-weight: 200;
  line-height: 1.6;
}

.meet-our-team,
.meet-our-advisors {
  background: white;
  padding: 3rem 1rem;
}

.meet-our-team {
  display: flex;
  flex-direction: column;
  gap: 40px 0;
  background-image: url('../../assets/landing-page/bg-map.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.meet-our-team h1,
.meet-our-advisors h1 {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 500;
}

.meet-our-team .quote,
.meet-our-advisors .quote {
  max-width: 700px;
  margin: 1rem auto;
  line-height: 30px;
}

.meet-our-advisors {
  background: #211f1f;
  color: #fff;
}

.meet-our-advisors .slick-arrow {
 display: block !important;
 top: 40%;
}

.meet-our-advisors .slick-next:before, 
.meet-our-advisors .slick-prev:before {
  color: #fff;
}

.advisors-list {
  max-width: 850px;
  margin: 0 auto;
}

.advisor-item p {
  color: #fff;
  font-size: 14px;
  font-family: Inter;
  margin-top: 0;
}

.team-list,
.advisors-list {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 260px));
  justify-content: center;
}

.team-item,
.advisor-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
}

.team-item img,
.advisor-item img {
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
}

.team-item h3 {
  margin: 10px 0 5px;
  border-left: 3px solid #5b5b5b;
  width: fit-content;
  padding-left: 1em;
  font-weight: 400;
  color: #000;
  font-size: 16px;
}

.advisor-item h3 {
  margin: 10px 0 5px;
  border-left: 3px solid #fff;
  width: fit-content;
  padding-left: 1em;
  font-size: 16px;
  font-weight: 400;
}

.team-item p {
  font-size: 14px;
  color: #646464;
  font-family: Inter;
}

.team-links {
  padding-left: 1em;
}

.team-item p,
.advisor-item p {
  font-family: Inter;
  font-weight: 200;
  margin: 0 0 10px calc(4px + 1em);
}

.team-links a,
.advisor-links a {
  margin: 0 5px;
}

.team-links img,
.advisor-links img {
  width: 20px;
  height: 20px;
}

.team-affiliations {
  margin-top: 10px;
  max-width: 200px;
}

.team-affiliations img {
  width: 50px;
  height: 50px;
  margin: 5px;
}
